.store-config-detail__store-info__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

/* STORE NAME */

.store-config-detail__store-info__container .store-name__container {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--skeleton-color);
    padding-bottom: 24px;
    width: 100%;
}

.store-config-detail__store-info__container .store-name {
    font-size: var(--body1);
    line-height: 23px;
}

/* STORE INFO */

.store-config-detail__store-info__container .store-info__item {
    flex: 1;
}

.store-config-detail__store-info__container .store-info__label {
    line-height: 20px;
    margin-bottom: 4px;
}

.store-config-detail__store-info__container .store-info__value {
    text-transform: none;
}
