.modal-upload-and-data-checking__container {
    border-radius: 12px;
    padding: 24px;
    max-width: 520px;

    .section-header {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        a {
            display: flex;
            align-items: center;
            border: none;
        }

        .title {
            color: #2a3d4a;
            text-align: center;
            font-size: var(--heading6);
            font-style: normal;
            font-weight: 600;
            font-family: Lato;
        }

        .guidline-text {
            line-height: 18px;
            letter-spacing: 0.005em;
            text-align: left;
            color: var(--color-primary);
            margin: 0 12px 0 6px;
            border-bottom: 1px solid var(--color-primary);
        }
    }

    .section-body {
        .description-selected-time-upload__container {
            background: var(--main-color-light);
            padding: 8px 12px;
            margin-bottom: 16px;
            border-radius: 8px;
            color: var(--Font, #4b4b4b);
            font-family: 'Lato';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.1px;
            position: relative;

            svg {
                position: absolute;
                top: -11px;
            }

            span {
                color: var(--Alert-Danger, #e03138);
                letter-spacing: 0.1px;
                text-decoration-line: underline;
                display: inline-flex;
                margin-left: 4px;
                cursor: pointer;

                svg {
                    margin-top: 2px;
                    position: static;
                }
            }
        }
        .label {
            font-size: var(--body1);
            font-weight: 600;
            line-height: 22.4px;
            letter-spacing: 0.005em;
            text-align: left;
            margin-bottom: 12px;
            color: black;
        }

        .tab-type-upload__container {
            .tab-item {
                padding: 12px;

                .tab-name {
                    white-space: nowrap;
                }

                svg {
                    fill: white;
                    margin-right: 12px;
                }
            }
        }
    }

    .section-footer {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .terms-and-condition__text {
            color: var(--semantic-text);
            font-size: var(--body2);
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.1px;
            margin-left: 8px;
            margin-bottom: 0 !important;

            a {
                color: var(--color-primary) !important;
                font-weight: 700 !important;
                cursor: pointer !important;
            }
        }

        .checkbox-container {
            margin-bottom: 28px;
            cursor: pointer;

            .chakra-checkbox__control {
                border: 1px solid var(--semantic-text);
                border-radius: 4px;
            }

            .checkbox-text {
                color: var(--Font, #4b4b4b);
                /* Body Text/Text S/Regular */
                font-family: Lato;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.06px;
                margin-left: 8px;
            }
        }

        .upload-button {
            text-align: center;
            color: #fff;
            background: #c4c4c4;
            margin-top: 32px;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.1px;
            width: 132px;
            align-self: center;
            margin-bottom: 2px;
            cursor: not-allowed;
        }

        .active-button {
            background: var(--main-color);
            cursor: pointer;
        }
    }
}

@media (max-width: 1610px) {
    .modal-upload-and-data-checking__container {
        padding: 20px;
        border-radius: 10px;

        .section-header {
            margin-bottom: 30px;

            .title {
                font-size: var(--heading8);
            }

            svg {
                width: 12px;
            }

            .search-icon__popup {
                width: 28px;
                height: 28px;
            }
        }

        .section-body {
            .description-selected-time-upload__container {
                font-size: 12px;
                line-height: 15px;
                padding: 7px 10px;
                margin-bottom: 12px;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            .label {
                font-size: 12px;
                margin-bottom: 12px;
            }

            .tab-type-upload__container {
                .tab-item {
                    height: 36px !important;
                    padding: 8px !important;
                }
            }
        }

        .section-footer {
            .terms-and-condition__text {
                font-size: 12px;
                line-height: 16px; /* 142.857% */
                margin-bottom: 6px;
            }

            .chakra-checkbox__control {
                width: 14px;
                height: 14px;

                svg {
                    width: 8px;
                    height: 8px;
                }
            }

            .checkbox-text {
                font-size: 9px;
                line-height: 14px; /* 133.333% */
                margin-left: 4px;
            }

            .upload-button {
                font-size: 12px;
                line-height: 20px; /* 142.857% */
                height: 36px;
            }
        }
    }
}
