:root {
    --color-primary: #005b99;

    --semantic-text: #2a3d4a;
    --semantic-heron: #f8f9fd;
    --skeleton-color: #ebebeb;
    --skeleton-active-color: #cfcfcf;

    --color-primary10: #e6eff5;
    --color-primary20: #b0ccdf;
    --color-primary30: #8ab4d0;
    --color-primary40: #337cad;
    --color-primary50: #005b99;
    --color-primary60: #00538b;
    --color-primary70: #00416d;
    --color-primary80: #003254;
    --color-primary90: #002640;

    --color-info10: #ccddf5;
    --color-info20: #99bbeb;
    --color-info30: #669ae0;
    --color-info40: #3378d6;
    --color-info50: #0a111b;
    --color-info60: #0045a3;
    --color-info70: #00347a;
    --color-info80: #002252;
    --color-info90: #000914;

    --color-success10: #d9f2e3;
    --color-success20: #b3e5c8;
    --color-success30: #8cd9ac;
    --color-success40: #66cc91;
    --color-success50: #40bf75;
    --color-success60: #33995e;
    --color-success70: #267346;
    --color-success80: #1a4c2f;
    --color-success90: #06130c;

    --color-warning10: #fffbe4;
    --color-warning20: #fff2ab;
    --color-warning30: #ffe86e;
    --color-warning40: #ffdd0c;
    --color-warning50: #ffcd00;
    --color-warning60: #d1a400;
    --color-warning70: #9d7b00;
    --color-warning80: #695200;
    --color-warning90: #342900;

    --color-neutral10: #ebebeb;
    --color-neutral20: #d6d6d6;
    --color-neutral30: #c2c2c2;
    --color-neutral40: #adadad;
    --color-neutral50: #999999;
    --color-neutral60: #7a7a7a;
    --color-neutral70: #5c5c5c;
    --color-neutral80: #3d3d3d;
    --color-neutral90: #1f1f1f;

    --color-danger10: #ffedef;
    --color-danger20: #f599a4;
    --color-danger30: #f06677;
    --color-danger40: #eb3349;
    --color-danger50: #e6001c;
    --color-danger60: #b80016;
    --color-danger70: #8a0011;
    --color-danger80: #5c000b;
    --color-danger90: #170003;

    --main-color: #005b99;
    --main-color-hover: #00528a;
    --main-color-active: #00497a;

    --main-color-light: #e6eff5;
    --main-color-light-hover: #d9e6f0;
    --main-color-light-active: #b0ccdf;

    --main-color-dark: #004473;
    --main-color-dark-hover: #00375c;
    --main-color-dark-active: #002945;

    --main-color-darker: #002036;

    --default-text-color: #2d2d2d;

    --default-white-color: white;

    --default-grey-color: #868686;
    --grey-color-light: #e6e6e6;
    --input-grey: #e2e8f0;
    --light-grey-color: #b1b1b1;

    --light-color: #e9eff6;

    --color-grey1: #7a7a7a;
    --color-grey2: #adadad;

    --color-border-grey1: #d6d6d6;

    --color-blue1: #2a3d4a;

    --color-red1: #e6001c;
    --color-red2: #ffedef;
    --color-red3: #b80016;
}
