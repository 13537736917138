.store-config-detail__head__wrapper {
    position: sticky;
    top: 116px;
    z-index: 10;
    padding-bottom: 24px;
    background: var(--semantic-heron);
}

@media (max-width: 1440px) {
    .store-config-detail__head__wrapper {
        top: 106px;
    }
}

.store-config-detail__head__wrapper::after {
    content: '';
    position: absolute;
    top: -46px;
    height: 65px;
    left: 0;
    right: 0;
    background: var(--semantic-heron);
    z-index: 1;
}

.store-config-detail__head__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 16px 24px 24px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 2;
}

/* TITLE */
.store-config-detail__head__container .title {
    font-size: var(--heading7);
    line-height: 30px;
    display: flex;
    gap: 16px;
    margin-bottom: 4px;
}

.store-config-detail__head__container .title > img {
    cursor: pointer;
}

/* DESCRIPTION */

.store-config-detail__head__container .description {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.store-config-detail__head__container .information__container img {
    margin-right: 8px;
    height: 16px;
    margin-top: 3px;
    width: 16px;
}

.store-config-detail__head__container .description span {
    font-weight: 700;
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
    margin-left: 2px;
}

.store-config-detail__head__container .description b {
    margin: 0 4px;
}

/* SAVE BUTTON */

.store-config-detail__head__container .save-button__text {
    color: white;
    font-size: var(--body1);
}

.store-config-detail__head__container .cancel-button__text {
    color: var(--color-primary);
    font-size: var(--body1);
}
