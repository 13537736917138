.blocked-notification__container {
    border: 1px solid #d6d6d6;
    padding: 12px;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 20px;
    background: #f8f9fd;
    justify-content: space-between;

    .box-icon {
        width: 16px;
    }

    .information-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: left;
        margin-left: 12px;
        width: 375px;

        span {
            font-weight: 700;
        }

        span:nth-child(2) {
            color: #0056cc;
            font-weight: 700;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .close-button {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #215aa8;
        width: 61px;
        cursor: pointer;
    }
}

@media (max-width: 1610px) {
    .blocked-notification__container {
        .information-text {
            width: 320px;
        }

        .close-button {
            width: 42px;
        }

        .information-text,
        .close-button {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
