.detail-modal__container {
    width: 560px;
}

.head {
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.head > img {
    cursor: pointer;
}

.title {
    font-size: var(--heading6);
}

.body {
    padding: 0 24px 24px;
    width: 100%;
}

.tab__container {
    border-bottom: 2px solid var(--color-neutral20);
    width: 100%;
    margin-bottom: 24px;
}

.tab__item {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.tab__item::before {
    content: '';
    position: absolute;
    bottom: -2px;
    display: block;
    width: 100%;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-neutral40);
}

.tab__text {
    font-size: var(--body2);
    font-weight: 600;
    color: var(--color-neutral40);
}

.tab__item--actived > .tab__text {
    color: var(--color-primary);
}

.tab__item--actived::before {
    border-bottom-color: var(--color-primary);
}

.sellout__information {
    flex-wrap: wrap;
}

.information__item {
    width: 33.33%;
    margin-bottom: 24px;
}

.information__title {
    margin-bottom: 4px;
}

.summary__container,
.error__container {
    padding: 16px;
    background: var(--semantic-heron);
    border-radius: 8px;
    margin-bottom: 34px;
}

.summary__info {
    background: white;
    padding: 4px 8px;
    border-radius: 6px;
}

.summary__text {
    font-size: var(--body3);
}

.summary__title {
    font-size: var(--body1);
}

.summary_brand {
    background: white;
    justify-content: space-between;
    border-radius: 6px;
    align-items: center;
    padding: 12px;
    margin-bottom: 8px;
}

.help-button,
.export-button {
    width: 135px;
    height: 40px;
    padding: 0 12px;
    align-items: center;
    border: 1px solid var(--color-border-grey1);
    border-radius: 4px;
    cursor: pointer;
    gap: 12px;
}

.error__box {
    background: var(--color-danger10);
    padding: 12px;
    border-radius: 8px;
}

.error__title {
    color: var(--color-danger60);
    margin-bottom: 12px;
    font-size: var(--body3);
}

.error__item {
    padding: 0 12px;
    gap: 8px;
    margin-bottom: 12px;
}

.error__item img {
    height: max-content;
    padding-top: 3px;
}

.error-item__container {
    max-height: 241px;
    overflow: auto;
}

.error-loader__box {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100px;
}
