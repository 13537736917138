.documentation-navbar{
    width: 260px;
    padding: 24px 0;
    border-right: 1px solid #D9E6F0;
    height: calc(100vh - 72px);
    position: sticky;
    top: 72px;
    padding-top: 24px;
    margin-top: 72px;
    // box-shadow: 0px 2px 8px 0px #0D0A2C08;

    // padding-left: 16px;

    .navbar-item{
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0 18px;
        padding: 6px 8px;
        margin-bottom: 16px;
        cursor: pointer;

        .navbar-text{
            font-size: var(--main-size);
            color: var(--main-color);
            font-weight: 500;
            margin-top: 2px;
        }
    }

    .navbar-item__actived{
        background: #D9E6F0;
        border-radius: var(--extra-small-radius);

        svg{
            path{
                stroke: var(--main-color-dark);
                stroke-width: 1.5;
            }
        }

        .navbar-text{
            font-weight: 600;
            color: var(--main-color-dark);

        }
    }
}