.store-config-detail__checkbox__container {
    gap: 12px;
    height: 65px;
    align-items: center;
    border-bottom: 1px solid var(--color-neutral10);
}

.store-config-detail__checkbox__container .text-label {
    line-height: 19.6px;
    margin-bottom: 4px;
}

.store-config-detail__checkbox__container .text-description {
    line-height: 16.8px;
    color: var(--color-neutral50);
}

.store-config-detail__checkbox__container .chakra-checkbox__control {
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-blue1);
    border-radius: 2px;
}

.store-config-detail__checkbox__container {
    border-radius: 2px;
}

.store-config-detail__checkbox__container .chakra-checkbox__control[data-checked] {
    background: var(--color-primary);
    border-radius: 2px;
}

.store-config-detail__checkbox__container .chakra-checkbox__control[data-checked] svg {
    width: 12px;
}
