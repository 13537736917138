.table-template2__container {
    width: 100%;

    .table-container {
        background: white;
        height: 100%;

        border-radius: 12px;
        min-height: 300px;

        .table-head__item::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 8px;
            top: 8px;
            // border-right: 1px solid #dfdfdf;
        }

        .divider::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            border-right: 1px solid #dfdfdf;
        }

        // .table-head__item:last-child:before,
        .table-column__item:last-child:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            // border-left: 1px solid #dfdfdf;
        }

        table {
            .row-head__container {
                overflow: hidden;
                // ----------------------------- TABLE HEAD ----------------------------

                .table-head__item {
                    z-index: 2;
                    padding: 16px 12px;

                    .head-title {
                        font-size: var(--body2);
                        text-transform: none;
                        line-height: 17px;
                        color: var(--semantic-text);
                    }
                }
            }
        }
    }
    // ----------------------------- TABLE COLUMN ----------------------------

    .row-column__container {
        background: white;
        position: relative;

        .click-row__container {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            right: 0;
            background: red;
            bottom: 0;
        }

        .table-column__item {
            padding: 16px 12px;
            position: relative;
            border-bottom: 1px solid #cecece;
            vertical-align: top;

            p {
                display: -webkit-box;
                font-size: var(--body2);
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .row-column__container--selected {
        background: var(--semantic-heron);
    }

    .row-skeleton .table-column__item > div {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .row-skeleton {
        .table-column__item {
            height: 73px;
        }
    }
    .row-column__container:last-child {
        .table-column__item {
            border-bottom: unset;
        }
    }

    //------------------------------- PAGINATION ----------------------------------------

    .section-pagination__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        .pagination__info {
            align-items: center;
            gap: 20px;
            width: max-content;
        }

        .pagination__info p {
            white-space: nowrap;
        }

        .pagination-limit__box {
            width: 90px;
            height: 34px;
            border-radius: 2px;
            font-size: var(--body3);
        }

        .showing-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: #5c6c8b;

            span {
                font-weight: 600;
                margin-left: 4px;
            }
        }

        .pagination-container {
            margin-top: 12px;

            ul {
                display: flex;
                list-style-type: none;
                align-items: center;
                gap: 8px;
            }

            .next-button {
                font-size: var(--body2);
            }

            a {
                font-size: var(--body2);
                color: var(--semantic-text);
            }

            img {
                margin-top: 2px;
            }

            .selected {
                background: var(--color-primary);
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2px;

                a {
                    color: white;
                }
            }
        }
    }
}

.table-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 1610px) {
    .table-template__container {
        .table-container {
            min-height: 280px;
        }
        .table-head__item {
            padding: 13px !important;
            font-size: 12px !important;
            line-height: 16px !important;
        }
        .table-column__item {
            padding: 9px 11px !important;

            p {
                font-size: 12px !important;
                line-height: 16px !important;
            }
        }

        .section-pagination__container {
            margin-top: 10px !important;

            .showing-text {
                font-size: 12px;
            }

            li {
                height: 28px !important;

                a {
                    height: 28px !important;
                    width: 28px !important;
                    font-size: 12px !important;
                }

                svg {
                    width: 20px !important;
                }
            }
        }
    }
}
