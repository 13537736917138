.detail-modal__container {
    width: 560px;
}

.head {
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.head img {
    cursor: pointer;
}

.title {
    font-size: var(--heading6);
}

.body {
    padding: 0 24px 24px;
}

.footer {
    padding: 0 24px;
}

.footer .box {
    padding: 16px;
    background: var(--semantic-heron);
    border-radius: 8px;
    margin-bottom: 24px;
}
