.documentation-page__container{
    width: 100%;
    margin: 0 auto;
    position: relative;

    .documentation-body{
        display: flex;
        margin: 0 auto;

        .documentation-content__container{
            flex: 1;
            margin-top: 72px;
        }
    }
}