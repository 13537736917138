.statistic__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.statistic__head {
    /* height: 44px; */
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-neutral10);
    margin-bottom: 24px;
}

.statistic__sellin,
.statistic__sellout {
    width: 50%;
}

.statistic__sellin {
    border-right: 1px solid var(--color-neutral10);
}

.statistic__sellout {
    margin-left: 32px;
}

.statistic__title {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: var(--body1);
    height: 44px;
}

.statistic__sellin-text,
.statistic__sellout-text {
    font-size: var(--body1);
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
}

.statistic__total-sellin-text,
.statistic__total-sellout-text {
    font-size: var(--heading4);
    margin-right: 12px;
}

.statistic__sellin-percentage,
.statistic__sellout-percentage {
    font-size: var(--body1);
}

.statistic__container .tooltip__content {
    top: 26px;
    left: -245px;
}
