.syncto-exagon-container {
    .force-run__container {
        svg {
            cursor: pointer;
        }
    }

    .label-sync {
        font-size: var(--body2);
        color: var(--color-primary40);
        font-weight: 600;
        margin: 32px 0 16px;
    }
}
