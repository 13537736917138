.table-store-config__container{
    @media (max-width: 1610px) {
        .column-text{
            svg{
                width: 13px;
            }
        }

        .table-column__item{
            .bagde-status-global__container{
                font-size: 10px !important;
            }
        }

     

        .action-icon__container__store-config{
            gap: 12px;

            svg{
                width: 22px;
            }
        }
    }
}

