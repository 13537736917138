.table__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.title {
    font-size: var(--heading7);
    margin-bottom: 24px;
}

.head {
    margin-bottom: 24px;
}

.column__container {
    height: 40px;
    align-items: center;
}

.detail__button {
    width: 62px;
    height: 36px;
    padding: 0 !important;
    position: relative;
}
