.error-page__container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        margin-bottom: 32px;
    }

    .error-page__title {
        font-size: var(--heading6);
        margin-bottom: 8px;
    }

    .error-page__description {
        margin-bottom: 32px;
        font-size: var(--body1);
        max-width: 600px;
        text-align: center;
    }

    .button-logout__text,
    .button-reload__text {
        font-size: var(--body1);
        color: var(--color-primary);
    }

    .button-reload__text {
        color: white;
    }

    button:hover .button-logout__text {
        color: white;
    }

    .box-info__container {
        background: var(--semantic-heron);
        border-radius: 8px;
        width: 600px;

        .toggle__button {
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 16px;
        }

        .info__container {
            display: flex;
            justify-content: space-between;
            background-color: var(--color-danger10);
            padding: 12px;
            border-radius: 8px;
            gap: 8px;
            margin: 0 16px 16px 16px;
        }
    }

    .back__button {
        background: var(--color-primary) !important;
        color: white;
        width: 175px;
        height: 42px;
        font-size: var(--body1);
        font-weight: 700;
        margin: 0 auto;
        gap: 12px;

        img {
            margin-bottom: 0;
        }
    }
}
