.achievement-dashboard__wrapper {
    width: calc(100vw - 380px);
    .achievement__container {
        background: #ffffff;
        border: 0.5px solid #d7d7d7;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        box-shadow: var(--shadow-box);

        .title-label {
            font-size: var(--main-size);
            letter-spacing: 0;
            margin-bottom: 24px;
            text-align: center;
            max-width: 820px;
        }

        .title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .spinner-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .achievement__container {
        overflow: scroll;
        height: calc(100vh - 140px);
    }

    .achievement__container::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1610px) {
        width: calc(100vw - 320px);
    }
}
