.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    overflow: hidden;

    .box {
        img {
            animation-name: logo-animation;
            animation-duration: 1s;
            margin-bottom: 16px;
        }

        .progress-bar__container {
            width: 146px;
            height: 6px;
            background: var(--main-color-light-active);
            animation-name: progress-animation;
            animation-fill-mode: forwards;
            border-radius: 8px;
            animation-duration: 0.8s;
            animation-delay: 0.5s;
            opacity: 0;

            .progress {
                width: 0;
                height: 100%;
                animation-name: progress-content-animation;
                animation-fill-mode: forwards;
                animation-duration: 3s;
                animation-timing-function: cubic-bezier(0.5, 0, 0.75, 1);
                animation-delay: 1s;
                background: var(--main-color);
                border-radius: 8px;
            }
        }
    }
}

.hide-splash-screen {
    animation-name: hide-splash-screen;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-duration: 0.5s;

    .progress-bar__container {
        opacity: 0;
        transition: 1s;
    }
}

@keyframes hide-splash-screen {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        height: 0;
        width: 0;
        opacity: 0;
        left: 50%;
        top: 50%;
    }

    100% {
        height: 0;
        width: 0;
        opacity: 0;
        left: 50%;
        top: 50%;
    }
}

@keyframes logo-animation {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes progress-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes progress-content-animation {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@media (max-width: 1610px) {
    .splash-screen {
        img {
            width: 130px;
            margin-bottom: 14px !important;
        }

        .progress-bar__container {
            width: 130px !important;
            height: 5px !important;
        }
    }
}
