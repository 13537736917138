.floating-menu__container {
    position: relative;

    .button-trigger {
        cursor: pointer;
    }

    .menu-list__container {
        position: absolute;
        right: 0;
        background: white;
        margin-top: 12px;
        z-index: 20;
        min-width: 160px;
        border-radius: 8px;
        box-shadow: var(--shadow-box);

        .menu-item__floating-button {
            cursor: pointer;
            width: 100%;

            p {
                font-size: var(--main-size);
                font-family: Lato;
            }
        }

        .menu-item__floating-button:hover {
            background: var(--semantic-heron);
        }
    }
}

@media (max-width: 1610px) {
    .floating-menu__container {
        .menu-item__floating-button {
            p {
                font-size: 12px;
            }
        }
    }
}
