.input-component__container {
    margin-top: 12px;
}

.text-label {
    margin-bottom: 8px;
}

.input {
    height: 40px !important;
    border: 1px solid var(--color-neutral20) !important;
    padding: 0 12px;
    font-size: var(--body2) !important;
    color: var(--semantic-text) !important;
    border-radius: 4px !important;
}

.input::placeholder {
    font-size: var(--body2);
    color: var(--color-neutral40);
}
