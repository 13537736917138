.time-select__wrapper {
    margin-bottom: 24px;

    .block-sellout__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #e6001c;

        span {
            font-weight: 700;
            margin-left: 4px;
        }
    }

    .time-select__container {
        border-radius: 8px;
        border: 1px solid #d6d6d6;
        min-height: 40px;
        position: relative;
        display: flex;
        padding: 12px;
        margin-bottom: 8px;
        background: var(--semantic-heron);
        cursor: not-allowed;
        display: flex;
        align-items: center;

        .react-datepicker-wrapper,
        .react-datepicker__input-container,
        input {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }

        .time-selected__text {
            color: var(--Font, #4b4b4b);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            margin-left: 12px;
        }

        .react-datepicker__month--selected {
            background: #2c54ba;
            color: white;
        }

        .reset-icon {
            position: absolute;
            top: 11px;
            right: 16px;
        }

        .week-picker {
            .react-datepicker__week:has(.react-datepicker__day:hover),
            .react-datepicker__day:hover {
                background: var(--main-color-light);
            }
        }
    }

    .time-select__actived {
        background: var(--White, #fff);
        border: 1px solid var(--main-color);
        cursor: pointer;
        transition: 0.3s;
    }

    @media (max-width: 1610px) {
        .time-select__container {
            min-height: 42px;
            padding: 8px 12px;

            .react-datepicker__current-month,
            .react-datepicker__header {
                font-size: 13px;
            }
            .react-datepicker__day,
            .react-datepicker__week-number,
            .react-datepicker__day-name,
            .react-datepicker__month-text {
                font-size: 12px;
            }

            .calendar-icon {
                width: 18px;
                height: 18px;
            }

            .time-selected__text {
                font-size: 12px;
                margin-top: 0;
            }

            .reset-icon {
                width: 18px;
                height: 18px;
            }
        }
    }
}
