.head-skeleton__audit-trail{
    justify-content: space-between;

    .search-filter{
        border-radius: 8px;
        border: 1.5px solid #C4C4C4;
        overflow: hidden;
        position: relative;
        margin-right: 16px;
        height: 44px;
        width: 278px;
        display: flex;
        align-items: center;
        padding:0 8px;
    }

    .filter{
        display: flex;
        border: 1.5px solid #C4C4C4;
        align-items: center;
        border-radius: 8px;
        height: 44px;
        width: 130px;
        padding: 0 8px;
        margin-right: 12px;
    }

    .add-button{
        width: 170px;
        height: 44px;
        display: flex;
        border: 1.5px solid #C4C4C4;
        align-items: center;
        border-radius: 8px;
        padding: 0 8px;
        margin-right: 8px;
    }
   
}