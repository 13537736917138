.table-user-management__container {
    .action-icon__container {
        gap: 16px;
    }

    .action-button {
        height: 32px;
        width: max-content;
        padding: 0 12px;
        background: var(--main-color-dark);
        color: white;
        font-size: 12px;
        border-radius: 8px;
    }

    .action-menu {
        width: 100%;
        padding: 12px;

        p {
            color: var(--Font, #4b4b4b);
            font-size: var(--small-size) !important;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            width: max-content;
        }
    }

    @media (max-width: 1610px) {
        .icon-access__container {
            svg {
                width: 13px;
            }
        }

        .action-icon__container {
            gap: 12px;
        }

        .action-icon {
            svg {
                width: 22px;
            }
        }

        .action-button {
            height: 26px !important;
            font-size: 8px !important;
        }

        .action-menu {
            padding: 10px;
            p {
                font-size: 10px !important;
            }
        }
    }
}
