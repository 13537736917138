.syncto-exagon-progress__modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;

    .overlay {
        position: fixed;
        z-index: 20;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    .modal-content {
        width: max-content;
        border-radius: 16px;
        background: white;
        position: relative;
        z-index: 21;
        flex-direction: column;
        padding: 32px 64px 100px;

        .head {
            .modal-title {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 120px;
            }
        }
    }

    .step-container {
        justify-content: center;
        gap: 60px;
        height: auto;
        align-items: center;

        .step-item {
            position: relative;

            .progress-bar {
                width: 186px;
                height: 7px;
                position: absolute;
                right: 190px;
                top: 12px;
                border-radius: 8px;
            }

            .circle-item {
                margin-bottom: 24px;
                height: 32px;
                width: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                .process-container {
                    padding: 8px;
                    width: 32px;
                    height: 32px;
                    background: #dadada;
                    border-radius: 50%;
                    margin: 0;

                    .blue-progress,
                    .grey-progress {
                        background: grey;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                    }
                }

                .inprogress-active {
                    background: #d2e4ff;

                    .blue-progress {
                        background: #0053d2;
                    }
                }

                .icon-success {
                    width: 18px;
                    height: 18px;
                }

                .icon-failed {
                    width: 14px;
                    height: 14px;
                }
            }

            .step-label {
                font-size: 10px;
                color: grey;
                font-family: Lato;
                margin: 0;
            }

            .step-name {
                font-weight: 600;
                margin: 4px 0 8px;
                font-size: 14px;
                font-family: Lato;
                width: 180px;
                height: 42px;
            }

            .step-status {
                margin: 0;
                font-size: 10px;
                font-family: Lato;
                font-weight: 500;
            }
        }

        .icon {
            display: none;
        }

        .progress-bar {
            .progress-content {
                display: none;
            }
        }

        .step-item__Success {
            .circle-item {
                background: #60d7bb;
            }

            .progress-bar {
                background: #60d7bb;
            }

            .step-status {
                color: #45a48e;
            }

            .icon-success {
                display: block !important;
            }
        }

        .step-item__Todo {
            .circle-item {
                .process-container {
                    background: #dadada;
                }
            }

            .progress-bar {
                background: #dadada;
            }

            .step-status {
                color: grey;
            }

            .icon-todo {
                display: block !important;
            }
        }

        .step-item__Failed {
            .circle-item {
                background: rgb(255, 89, 89);

                .icon-failed {
                    display: block !important;
                }
            }

            .progress-bar {
                background: rgb(255, 89, 89);
            }

            .step-status {
                color: rgb(255, 89, 89);
            }
        }

        .step-item__Inprogress {
            .circle-item {
                .process-container {
                    background: #dee7ed;
                    position: relative;

                    .blue-progress {
                        background: rgb(18, 86, 188);
                        animation: growAndFade 2s infinite ease-out;
                        border-radius: 50%;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        width: 100%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }

                    .circle1 {
                        animation-delay: 0.5s;
                    }

                    .circle2 {
                        animation-delay: 1s;
                    }

                    .circle3 {
                        animation-delay: 2s;
                    }
                }
            }

            .progress-bar {
                background: #dadada;
                opacity: 0.6;

                .progress-content {
                    display: block;
                    width: 100%;
                    background: var(--main-color);
                    height: 100%;
                    border-radius: 8px;
                    animation-name: progressBarAnimation;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                }
            }

            .step-status {
                color: rgb(18, 86, 188);
            }

            .icon-inprogress {
                display: block;
            }
        }
    }
}

@keyframes progressBarAnimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes growAndFade {
    0% {
        opacity: 0.5;
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@media (max-width: 1610px) {
    .replenisment-progress__modal {
        .modal-content {
            padding: 24px 48px 64px;
            border-radius: 8px;

            .head {
                .modal-title {
                    font-size: var(--large-size);
                    margin-bottom: 50px;
                }
            }

            .step-container {
                gap: 24px;

                .circle-item {
                    width: 24px;
                    height: 24px;

                    .process-container {
                        width: 24px;
                        height: 24px;
                    }

                    .icon-failed {
                        width: 8px;
                        height: 8px;
                    }

                    .icon-success {
                        width: 10px;
                        height: 10px;
                    }
                }

                .step-item {
                    .progress-bar {
                        width: 160px;
                        right: 190;
                        top: 8px;
                    }

                    .step-label {
                        font-size: 8px;
                    }

                    .step-name {
                        font-size: var(--small-size);
                    }

                    .step-status {
                        font-size: 8px;
                    }
                }
            }
        }
    }
}
