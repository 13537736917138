.login-password__container {
    background: var(--main-color);
    display: flex;
    height: 100vh;
    width: 100vw;

    .left-section {
        width: 50%;
        height: 100vh;
        position: relative;

        .circle-1 {
            position: absolute;
            right: 0;
            top: 0;
        }

        .circle-2 {
            position: absolute;
            left: 0;
            bottom: 100px;
        }

        .box-login__left {
            position: relative;
            z-index: 2;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 55px;
            padding-top: 105px;

            .image-logo {
                margin-bottom: 42px;
            }

            .title {
                font-size: 32px;
                font-weight: 400;
                line-height: 44.8px;
                letter-spacing: 0.005em;
                text-align: left;
                margin-bottom: 21px;
                color: white;
                width: 80%;
                margin-bottom: 21px;
            }

            .desc {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                letter-spacing: 0.005em;
                text-align: left;
                color: white;
                width: 80%;
            }

            .image-dashboard__container {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;

                .image-dashboard {
                    width: 70%;
                }
            }
        }
    }

    .right-section {
        width: 50%;
        background: white;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .error-container {
            border: 1px solid var(--color-red1);
            background: var(--color-red2);
            border-radius: 4px;
            padding: 12px 16px 12px 16px;
            align-items: center;
            margin-bottom: 40px;
            align-items: flex-start;

            .error-text {
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: 0.005em;
                text-align: left;
                margin-left: 12px;
            }
        }

        .box-login__right {
            max-width: 480px;
        }

        .title {
            font-size: 26px;
            font-weight: 600;
            line-height: 36.4px;
            letter-spacing: 0.005em;
            text-align: left;
            margin-bottom: 8px;
        }

        .desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            letter-spacing: 0.005em;
            text-align: left;
            color: var(--color-grey1);
            margin-bottom: 40px;
        }

        .input-container {
            margin-bottom: 24px;

            .label {
                font-size: 14px;
                font-weight: 700;
                line-height: 19.6px;
                letter-spacing: 0.005em;
                text-align: left;
                color: var(--color-blue1);
                margin-bottom: 8px;

                span {
                    color: var(--color-red1);
                }
            }

            .input {
                height: 44px;
                border-radius: 4px;
                border: 1px solid var(--color-border-grey1);

                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
            }

            .input::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: 0.005em;
                text-align: left;
                color: var(--color-grey2);
            }

            svg {
                position: absolute;
                right: 15px;
                top: 12px;
                cursor: pointer;
                z-index: 10;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
            }
        }

        .oauth-container {
            margin-top: 16px;
            margin-bottom: 80px;
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;

            .or-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: 0.005em;
                text-align: left;
                margin-bottom: 16px;
            }

            a {
                width: 100%;
            }

            .oauth-button {
                display: flex;
                background: white;
                align-items: center;
                justify-content: center;
                height: 42px;
                gap: 12px;
                border: 1px solid var(--color-border-grey1);
                border-radius: 6px;
                width: 100%;
                cursor: pointer;

                .outlook-text {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22.4px;
                    letter-spacing: 0.005em;
                    text-align: left;
                }
            }

            .oauth-button::hover {
                background: white !important;
            }
        }

        .copyright {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.005em;
            text-align: left;
            color: var(--color-blue1);
            text-align: center;
        }

        .button-submit {
            margin-top: 28px;
            height: 42;
            border-radius: 6px;
            color: white;
            width: 100%;

            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
            background: var(--main-color);
            cursor: pointer;
        }

        .validation-container {
            .validation-item {
                margin-bottom: 4px;

                .validation-text {
                    margin-left: 16px;
                    font-family: Lato;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #868686;
                }

                .validation-text__actived {
                    color: #2ea31f;
                }
            }
        }
    }
}

@media (max-width: 1610px) {
    .login-password__container {
        .box-login__left {
            padding-left: 32px;

            .title {
                font-size: 24px !important;
                line-height: 32px !important;
                margin-bottom: 16px !important;
            }

            .desc {
                font-size: 14px !important;
            }

            .image-logo {
                margin-bottom: 28px !important;
            }
        }

        .right-section {
            .box-login__right {
                max-width: 400px;
            }

            .error-container {
                padding: 10px 12px;
                .error-text {
                    font-size: 12px;
                }
            }
            .title {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 4px;
            }

            .desc {
                font-size: 14px;
            }

            .label {
                font-size: 12px !important;
            }

            .input-container {
                margin-bottom: 18px !important;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            .input {
                height: 38px !important;
                line-height: 16px !important;
                font-size: 12px !important;
            }

            .input::placeholder {
                font-size: 12px !important;
                line-height: 16px !important;
            }

            .oauth-container {
                margin-top: 10px;
                margin-bottom: 60px;

                .or-text {
                    font-size: 12px;
                }

                .oauth-button {
                    height: 36px;

                    .outlook-text {
                        font-size: 12px !important;
                    }
                }
            }

            .copyright {
                font-size: 10px !important;
            }

            .button-submit {
                height: 36px;
                font-size: 14px;
                margin-top: 16px;
            }
        }
    }
}
