.modal-confirmation__container {
    width: 100%;
    padding: 24px;
    border-radius: 8px;
}

.title {
    font-size: var(--heading6);
    line-height: 36px;
}

.description {
    margin-bottom: 24px;
    line-height: 19.6px;
}
