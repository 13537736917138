.add-edit-usermanagement__container {
    background: white;
    height: max-content;
    border-radius: 18px;
    box-shadow: 0px 2px 15px 0px #0d0a2c08;
    flex-direction: column;

    .head {
        gap: 16px;
        align-items: center;
        height: max-content;
        border-bottom: 1px solid #e4e4e4;
        width: 100%;
        padding: 24px;


        .icon-back {
            width: 32px;
            height: 32px;
            background: var(--main-color-light-active);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
        }

        .title {
            font-size: 18px;
            font-weight: 600;
            line-height: 38px;
            cursor: pointer;
        }
    }

    .body {
        padding: 32px 0 48px;
        max-width: 530px;
        margin: 0 auto;
        height: max-content;
        width: 100%;


        .form-item {
            margin-bottom: 18px;
            position: relative;

            .label {
                font-size: var(--main-size);
                font-weight: 600;
                margin-bottom: 8px;
            }

            .eye-icon {
                position: absolute;
                right: 12px;
                top: 43px;
                cursor: pointer;
                z-index: 5;
            }
        }

        .button-submit {
            margin-top: 24px;
            width: 100%;
            background: var(--main-color);
            color: white;
            font-size: var(--main-size);
            height: 48px;

        }
    }
}


@media (max-width: 1610px) {

    .add-edit-usermanagement__container {
        border-radius: 16px;

        .head {
            gap: 12px;
            padding: 18px;

            .icon-back {
                width: 26px;
                height: 26px;

                svg {
                    width: 18px;
                }
            }

            .title {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .body {
            padding: 24px 0 36px;
            max-width: 430px;

            .form-item {
                margin-bottom: 14px;

                .label {
                    font-size: 12px;
                    margin-bottom: 6px;
                }

                .eye-icon {
                    width: 16px;
                    top: 30px;
                    right: 11px;
                }
            }

            .button-submit {
                height: 36px;
                font-size: 12px;
                margin-top: 16px;
            }

        }
    }
}