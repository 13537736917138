.trend__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

/* HEAD */

.trend__head {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 24px;
}

.year-text {
    margin-right: 8px;
}

.trend__title {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: var(--body1);
    height: 44px;
}

.canvas-zone {
    position: relative;
    height: 300px;
}

/* CHART TOGGLE */

.chart-toggle__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    gap: 24px;
}

.trend-sellin__label,
.trend-sellout__label {
    margin-left: 8px;
    line-height: 20px;
}

.chart-toggle__container .chakra-checkbox__control {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-blue1);
    border-radius: 2px;
}

.chart-toggle__container .chakra-checkbox__control[data-checked],
.chart-toggle__container .chakra-checkbox__control[data-checked]:hover {
    background: #d1a400;
    border: 1px solid #d1a400;
    border-radius: 2px;
}

.chart-toggle__container .chakra-checkbox__control[data-checked]:hover {
    background: #d1a400;
    border: 1px solid #d1a400;
}

.chart-toggle__container .chakra-checkbox__control[data-checked] svg {
    width: 8px;
}

.trend-sellout__container .chakra-checkbox__control[data-checked],
.trend-sellout__container .chakra-checkbox__control[data-checked]:hover {
    background: var(--color-primary);
    border-color: var(--color-primary);
}

.trend__container .tooltip__content {
    top: 28px;
    left: -246px;
}
