:root {
    --main-color: #ff9494;
    --main-color-hover: rgb(232, 126, 126);
    --main-color-active: #d77e7e;

    --main-color-light: #e6eff5;
    --main-color-light-hover: #d9e6f0;
    --main-color-light-active: #b0ccdf;

    --main-color-dark: #004473;
    --main-color-dark-hover: #00375c;
    --main-color-dark-active: #002945;

    --main-color-darker: #002036;

    --default-text-color: #2d2d2d;

    --default-white-color: white;

    --default-grey-color: #868686;
    --grey-color-light: #e6e6e6;
    --light-grey-color: #c8d0d6;

    --light-color: #ffedf0;

    --color-grey1: #7a7a7a;
}
