.filter-loader {
    margin: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.loader__text {
    font-size: var(--body1);
    margin-bottom: 4px;
}

.description__text {
    font-size: var(--body3);
    color: var(--color-neutral70);
}
