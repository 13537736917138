.sort-container{
    position: relative;

    .sort-button{
        border: 1px solid var(--main-color);
        width: 40px;
        height: 40px;
        padding: 0;
        background: white;
    }

    .sort-button:hover{
        background: var(--main-color-light-active);

    }

    .sort-item{
        padding: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            font-size: var(--main-size);
            width: 100%;
        }
    }

    .circle-active{
        display: none;
    }

    .sort-active{
        background: var(--main-color);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        top: -6px;
        right: -6px;

    }

    .sort-selected{
        p{
            font-weight: 600;
            color: var(--main-color);
        }

        .circle-active{
            display: flex;
            background: #e0c900;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
    }
}

@media (max-width: 1610px) {
    .sort-container{
        .sort-button{
            height: 35px;
            width: 35px;
            min-width: unset;
            
            svg{
                width: 18px;
            }
        }

        .sort-item{
            padding: 12px !important;

            p{
                font-size: 12px !important;
            }
        }

        .menu-list__floating-button{
            width: 180px;
            min-width: unset;
        }
    }
}