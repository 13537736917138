.actions-container__audit-trail {
    .action-button {
        height: 32px;
        width: max-content;
        padding: 0 12px;
        background: var(--main-color-dark);
        color: white;
        font-size: 12px;
        border-radius: 8px;
    }

    .menu-item__floating-button {
        border-bottom: none;
    }

    .action-menu {
        width: 100%;
        padding: 12px;

        p {
            color: var(--Font, #4b4b4b);
            font-size: var(--small-size) !important;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            width: max-content;
        }
    }

    @media (max-width: 1610px) {
        .action-button {
            height: 26px !important;
            font-size: 8px !important;
        }
        .action-menu {
            padding: 10px;
            p {
                font-size: 10px !important;
            }
        }
    }
}

.date-sellout-column__audit-trail {
    // display: flex;
    gap: 16px;
    position: relative;

    .see-more__button {
        font-size: 10px !important;
        color: var(--main-color);
        cursor: pointer;
        width: max-content;
    }

    .date-item {
        margin-bottom: 12px;
        color: var(--main-color-darker);
        font-weight: 500;
        font-size: 13px !important;
    }
}

.qty-sellout-column__audit-trail {
    display: flex;
    flex-direction: column;

    .qty-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .see-more__button {
        font-size: 10px !important;
        color: var(--main-color);
        cursor: pointer;
    }

    .qty-item {
        margin-bottom: 12px;
        color: var(--main-color-darker);
        font-weight: 500;
        font-size: 13px !important;
    }
}
