.text-component__container {
    justify-content: space-between;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid var(--color-neutral10);
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
