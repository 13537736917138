.select-container {
    position: relative;
}

.select-title {
    margin-bottom: 8px;
}

.select-button {
    height: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}

.chevron-down__icon {
    position: absolute;
    right: 14px;
    z-index: 2;
}

.search-input {
    width: 100%;
    border: 1px solid var(--color-border-grey1) !important;
    font-size: var(--body2) !important;
    color: var(--semantic-text);
}

.search-input::placeholder {
    color: var(--color-grey2);
    font-size: var(--main-size);
}

.select-options__container {
    z-index: 5;
    position: absolute;
    max-height: 280px;
    overflow: auto;
    height: max-content;

    border: 1px solid var(--color-border-grey1);
    background: white;
    top: 74px;
    border-radius: 4px;
    animation-name: options-animation;
    animation-duration: 0.5s;
}

.select-options__container::-webkit-scrollbar {
    width: 3px;
}

.select-options__container::-webkit-scrollbar-thumb {
    background-color: var(--color-neutral50);
}

@keyframes options-animation {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.option-item {
    min-height: 44px;
    width: 100%;
    gap: 12px;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid var(--color-border-grey1);
}

.option-checkbox .chakra-checkbox__control {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-blue1);
    border-radius: 2px;
}

.option-checkbox {
    border-radius: 2px;
}

.option-checkbox .chakra-checkbox__control[data-checked] {
    background: var(--color-primary);
    border-radius: 2px;
}

.option-checkbox .chakra-checkbox__control[data-checked] svg {
    width: 8px;
}

.selected-option-item {
    background: var(--semantic-heron);
}

.option-item {
    position: relative;
}

.option-item .option-onclick__zone {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
}

.option-item .reload-text {
    font-size: var(--body3);
    color: var(--color-primary);
}
