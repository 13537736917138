.section-head__store-config{
    margin-bottom: 24px;

    .title{
        font-size: var(--extra-large-size);
        font-weight: 700;
        line-height: 38px;
        text-align: left;
        margin-bottom: 36px;

    }

    .icon{
        padding: 10.5px;
        background-color: #e5e9f2;
        border-radius: 100%;
        cursor: pointer;
    }

    .list-button__store-config{
            padding: 16px;
            width: 100%;
    }

    .ondownload-process{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: not-allowed !important;
        background: rgb(228, 228, 228) !important;
        
        p{
            color: grey !important;
        }
    }

    .dotted-icon{
        height: 40px;
    }

}



@media (max-width: 1610px) {
    .section-head__store-config{
        .title{
            font-size: 15px;
            margin-bottom: 22px;
        }

        .dotted-icon{
            height: 36px;
            display: flex;
            align-items: center;

            svg{
                width: 26px;
            }
        }

        .menu-list__floating-button{
            width: 180px;
            min-width: unset;
        }

        .list-button__store-config{
            padding: 12px;
        }
          
    }
}