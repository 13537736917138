.table-component__container .chakra-checkbox__control[data-checked],
.table-component__container .chakra-checkbox__control[data-checked]:hover {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 2px;
}

.table-component__container .chakra-checkbox__control[data-checked]:hover {
    background: #d1a400;
    border: 1px solid #d1a400;
}

.table-component__container .chakra-checkbox__control[data-checked] svg {
    width: 12px;
}

.table-component__container.chakra-checkbox__control[data-checked],
.table-component__container .chakra-checkbox__control[data-checked]:hover {
    background: var(--color-primary);
    border-color: var(--color-primary);
}

.table-component__container .chakra-checkbox__control {
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-blue1);
    border-radius: 2px;
}

.table-component__container .chakra-checkbox__control[data-checked][data-disabled] {
    border-color: var(--color-primary20) !important;
    background: var(--color-primary20) !important;
    color: var(--color-primary20) !important;
}

.table-component__container .chakra-checkbox__control[data-checked][data-disabled] svg {
    stroke: white !important;
}
