.overview__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.title {
    font-size: var(--heading7);
}

.last-update__text {
    font-size: var(--body3);
}

.last-update__text span {
    font-weight: 700;
}

.information__container {
    align-items: center;
}

.information__container img {
    width: 16px;
}

.overview__item {
    background: var(--semantic-heron);
    flex: 1;
    height: 90px;
    padding: 16px;
    border-radius: 8px;
}

.overview-item__text {
    font-size: var(--heading7);
}

.overview-item__head {
    margin-bottom: 8px;
    align-items: center;
    gap: 4px;
}
