.table__container .chakra-checkbox__control {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-blue1);
    border-radius: 2px;
}

.table__container .chakra-checkbox__control[data-checked] {
    background: var(--color-primary);
    border-radius: 2px;
}

.table__container .chakra-checkbox__control[data-checked] svg {
    width: 8px;
}
