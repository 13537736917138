
.filter-dropdown__container{
    position: relative;
    z-index: 4;

    .filter-button{
        position: relative;
        background:white;
        margin:0 6px;
        max-width: 145px;
        transition: 0.5s;
        width: 100%;

        input{
            font-size: var(--main-size);
            font-weight: 500;
            border: 1.5px solid var(--main-color);
            border-radius: 8px;
            color: var(--main-color);
            height: 44px;

        }

        input::placeholder{
            color: var(--main-color);
            font-weight: 500;
        }
    
        .icon-container{
            position: absolute;
            right: 4px;
            top: 0;
            height: 44px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .filter-selected__number{
            background: var(--main-color);
            width: 22px;
            padding-top: 2px;
            height: 22px;
            border-radius: 50%;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            position: absolute;
            left: 8px;
            top: 9px;
        }

    }

    .filter-button__actived{
        max-width: 200px;
    }

    .filter-list{
        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    .filter-list__actived{
        opacity: 1;
        height: max-content;
        max-height: 360px;
        width: 200px;
        background: white;
        border-radius: 8px;
        position: absolute;
        top: 56px;
        right: 0;
        box-shadow: var(--shadow-box-small);
        overflow: auto;
        animation-name: filter-list-animation;
        animation-duration: 0.5s;

        .filter-item{
            padding: 12px;
            border-bottom: 1px solid rgb(196, 196, 196);
            cursor: pointer;

            .filter-text{
                font-size: 14px;
            }
        }

        .filter-item:hover{
            background: var(--main-color-light);

            .filter-text{
                color: var(--main-color-active);
            }
        }

        .filter-item:last-child{
            border-bottom: none;
        }

        .filter-selected{
            color: var(--main-color-dark);
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .circle-active{
                background: #e0c900;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }
    }
@keyframes filter-list-animation {
    0%{
        opacity: 0;
        transform: translateY(-20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);

    }
}


  

    .filter-list__actived::-webkit-scrollbar {
        width: 3px;
      }
      
      .filter-list__actived::-webkit-scrollbar-thumb{
        background-color: var(--main-color-active);
      }
}


@media (max-width: 1610px)  {
    .filter-dropdown__container{

        .filter-button{
            max-width: 124px;
            margin: 0 4px;

            input{
                font-size: 12px !important;
                line-height: 16px !important;
                height: 35px !important;
                border-radius: 6px !important;
                padding: 10px 13px 8px !important;
            }

            .input-container__selectedfilter{
                padding-left: 30px !important;
            }

            .icon-container{
                height: 35px !important;
                right: -2px;

                svg{
                    width: 19px;
                }
            }
        }

        .filter-button__actived{
            max-width: 160px;
        }

        .filter-selected__number{
            width: 16px !important;
            height: 16px !important;
            font-size: 8px !important;
            left: 8px !important;
            top: 10px !important;
        }

        .filter-list__actived{
            width: 160px;
            top: 46px;

            .filter-item{
                padding: 8px;

                .filter-text{
                    font-size: 11px;
                }
            }
        }
    }
}