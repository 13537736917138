:root {
    --extra-large-size: 20px;
    --large-size: 16px;
    --main-size: 14px;
    --small-size: 12px;
    --extra-small-size: 10px;

    --display1: 60px;
    --display2: 52px;

    --heading1: 48px;
    --heading2: 42px;
    --heading3: 38px;
    --heading4: 32px;
    --heading5: 26px;
    --heading6: 24px;
    --heading7: 20px;
    --heading8: 18px;

    --body1: 16px;
    --body2: 14px;
    --body3: 12px;

    --caption1: 10px;
    --caption2: 8px;
}

p {
    margin: 0;
    font-family: Lato;
}
