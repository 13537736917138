.header-global-container {
    height: 80px;
    width: 100%;
    background: var(--default-white-color);
    box-shadow: var(--shadow-box);
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    position: sticky;
    top: 0;
    z-index: 20;

    .date-text {
        font-size: 14px;
    }

    .badge-week {
        font-size: var(--small-size);
        color: var(--color-primary);
        background: var(--color-info10);
        padding: 3.5px 6px;
        border-radius: 4px;
        height: max-content;
    }

    .circle-shortname {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: var(--color-primary10);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-primary60);
        font-size: var(--body3);
        font-weight: 500;
        line-height: 14.52px;
        position: relative;

        .active-indicator {
            background: var(--color-success60);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            border: 2px solid white;
        }
    }

    svg {
        cursor: pointer;
        width: 20px;
    }

    .floating-button__wrapper {
        padding: 12px 16px;
        align-items: center;
        width: 100%;
        gap: 12px;

        p {
            flex: 1;
        }
    }

    .button-element__container {
        margin-left: 32px;
    }

    .username-text {
        font-size: var(--main-size);
    }

    .role-text {
        font-size: var(--body3);
        color: var(--color-grey1);
    }
}

.header-container::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: white;
}

@media (max-width: 1610px) {
    .header-container {
        height: 56px;
        padding: 0 16px;

        .badge-container {
            min-height: 24px !important;
            padding: 0 10px;

            .text {
                font-size: var(--extra-small-size);
            }
        }

        .username-text {
            font-size: 12px;
        }

        .menu-list__container {
            .floating-button__wrapper {
                padding: 8px 12px;

                p {
                    font-size: var(--small-size) !important;
                }
            }
        }

        .button-element__container {
            margin-left: 24px;
        }

        svg {
            width: 18px;
        }

        .people-icon {
            width: 28px;
        }
    }
}
