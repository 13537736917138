.profile-container {
    width: 520px;
    border-radius: 8px;
    height: max-content;
    background: white;
    box-shadow: var(--shadow-box);
    position: sticky;
    top: 110px;
    padding: 24px;

    .label {
        font-size: 15px;
        color: var(--main-color);
        font-weight: 600;
        margin-bottom: 24px;
    }

    .text-item {
        justify-content: space-between;
        margin-bottom: 14px;
        margin-right: 12px;

        .text-label {
            font-size: var(--main-size);
        }

        .text-value {
            font-size: var(--main-size);
            color: #2f2f2f;
            text-align: right;
            max-width: 340px;
        }
    }
}


@media (max-width: 1610px) {
    .profile-container {
        width: 480px;

        .label {
            font-size: var(--small-size);
        }



        .text-item {
            margin-bottom: 8px;

            .text-label,
            .text-value {
                font-size: var(--small-size);
            }

            .text-value {
                max-width: 300;
            }
        }
    }
}