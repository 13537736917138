.select-form__container {
    position: relative;

    .field-required__button {
        border: 1.5px solid #db3535 !important;

        // input::placeholder{
        //     // color: red !important;
        // }
    }

    .field-required__text {
        color: red;
        font-size: 11px;
        margin-top: 4px;
    }

    .select-button {
        position: relative;
        background: white;
        transition: 0.5s;
        width: 100%;
        display: flex;
        border: 1.5px solid #c4c4c4;
        border-radius: 8px;
        min-height: 36px;
        height: max-content;
        overflow: hidden;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px 32px 8px 16px;
        gap: 4px;

        .selected-text {
            font-size: 12px;
            font-weight: 600;
            color: var(--main-color);
            text-transform: capitalize;
        }

        input {
            font-size: 13px;
            border: none !important;
            font-weight: 500;
            color: var(--main-color);
            height: 32px;
            flex: 1;
            padding-left: 0;
            min-width: 100px;
        }

        input:focus {
            box-shadow: none !important;
        }

        input:disabled {
            opacity: 1;
        }

        .icon-select {
            background: white;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 4;
            }
        }
    }

    .form-list {
        opacity: 1;
        height: max-content;
        max-height: 360px;
        background: white;
        border-radius: 8px;
        position: absolute;
        top: 56px;
        right: 0;
        left: 0;
        box-shadow: var(--shadow-box-small);
        overflow: auto;
        animation-name: filter-list-animation;
        animation-duration: 0.5s;
        z-index: 10;
        border: 1px solid rgb(195, 195, 195);

        .not-found__text {
            width: 100%;
            color: rgb(172, 169, 169);
            text-align: center;
            height: 100px;
            font-size: var(--small-size);
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .select-item {
            padding: 12px;
            border-bottom: 1px solid rgb(196, 196, 196);
            cursor: pointer;

            .select-text {
                font-size: 14px;
            }
        }

        .select-item:hover {
            background: var(--main-color-light);

            .select-text {
                color: var(--main-color-active);
            }
        }

        .select-item:last-child {
            border-bottom: none;
        }

        .select-item__active {
            color: var(--main-color-dark);
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .circle-active {
                background: #e0c900;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }
        }
    }

    @keyframes filter-list-animation {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.form-list::-webkit-scrollbar {
    width: 3px;
}

.form-list::-webkit-scrollbar-thumb {
    background-color: var(--main-color-active);
}

@media (max-width: 1610px) {
    .select-form__container {
        .field-required__text {
            font-size: 8px !important;
        }

        .select-button {
            min-height: 35px !important;
            border-radius: 6px !important;
            padding: 0 24px 0 12px !important;

            input {
                font-size: 10px !important;
                height: 28px !important;
            }

            .selected-item {
                padding: 0 6px;
                height: 28px;
                border-radius: 6px !important;

                .icon {
                    height: 12px;
                    width: 12px;

                    svg {
                        width: 5px;
                        height: 5px;
                    }
                }

                .selected-text {
                    font-size: 9px;
                }
            }
        }

        .select-item {
            padding: 10px !important;

            .select-text {
                font-size: 12px !important;
            }
        }
    }
}
