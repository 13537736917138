.select-container {
    position: relative;
}

.select-title {
    margin-bottom: 8px;
}

.search-input {
    width: 100%;
    border: 1px solid var(--color-border-grey1) !important;
    font-size: var(--body2) !important;
    color: var(--semantic-text);
    position: relative;
    padding-left: 38px !important;
    border-radius: 4px !important;
    background: white !important;
}

.search-input::placeholder {
    color: var(--color-grey2);
    font-size: var(--main-size);
}

.search-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
}

.box-filter {
    position: relative;
}
