.sagittarius-text__reguler {
    font-size: var(--body2);
    font-weight: 400;
    color: var(--semantic-text);
}

.sagittarius-text__medium {
    font-size: var(--body2);
    font-weight: 500;
    color: var(--semantic-text);
}

.sagittarius-text__semibold {
    font-size: var(--body2);
    font-weight: 600;
    color: var(--semantic-text);
}

.sagittarius-text__bold {
    font-size: var(--body2);
    font-weight: 700;
    color: var(--semantic-text);
}

.sagittarius-text__extrabold {
    font-size: var(--body2);
    font-weight: 800;
    color: var(--semantic-text);
}
