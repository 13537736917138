.store-config__container{
    height: calc(100vh - 144px);
    overflow: auto;
}

.store-config__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.store-config__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
