.table-head__container__achievement {
    width: max-content;

    .floating-action-head {
        position: sticky;
        right: 0;

        border-radius: 0px;
        display: flex;
        justify-content: flex-end !important;
        flex-direction: column;
        align-items: flex-end !important;
        height: max-content;

        p {
            background: #e8ecf6;
            border-width: 2px 2px 2px 0px;
            line-height: 29px;
            width: 100px;

            margin-bottom: 4px;
        }

        .grey-box {
            line-height: 28px !important;
            width: 100px;
            align-items: center !important;
            background: #ededed;
            text-align: center !important;
        }
    }

    .table-head__item:first-child {
        .table-head__parent,
        .table-head__child {
            padding-left: 12px;
        }
    }

    .table-head__item:last-child {
        .table-head__child {
            padding-right: 12px;
        }
    }

    #table-head__item--0 {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    .table-head__item {
        display: flex;
        align-items: center;
        background: var(--main-color-light);

        font-family: var(--main-font);
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #2d2d2d;

        .table-head__parent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 33px;

            font-weight: 600;
            font-size: 11px;

            @media (min-width: 1441px) {
                font-size: 13px;
            }

            line-height: 21px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 4px;
            padding-left: 16px;
        }

        .table-head__child-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1;
            width: 100%;
            height: 28px;
        }

        .table-head__child {
            border-right: 1px solid #cecece;
            text-align: center;
        }

        .table-head__child,
        .grey-box {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;

            font-size: 11px;

            @media (min-width: 1441px) {
                font-size: 13px;
            }
            line-height: 18px;
            text-align: center;
            text-transform: none;

            background: #ededed;

            color: #2d2d2d;
        }

        .table-head__child {
            width: 100%;
        }
    }

    #table-head__item--2 {
        .table-head__parent {
            padding-left: 16px;
        }

        .table-head__child {
            padding-left: 16px;
        }
    }

    .table-head__item__grey-box {
        border-left: 1px solid #cecece;
    }
}
