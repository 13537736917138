.dashboard-skeleton {
    padding: 36px 41px 60px;

    .reminder {
        background: var(--main-color-light);
        height: 164px;
        margin-bottom: 56px;
        border-radius: 8px;
        padding: 16px;
    }

    .upload {
        margin-bottom: 56px;

        .download-menu {
            background: var(--main-color-light);
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 0 16px;
            gap: 8px;
        }

        .upload-zone {
            background: var(--main-color-light);
            height: 223px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .table {
            table {
                background: var(--main-color-light);
                border-radius: 8px;
            }
        }
    }

    .table-dashboard__skeleton {
        table {
            border-radius: 8px;

            .table-head__item {
                background-color: var(--main-color-light);
            }
        }

        .filter-search {
            padding: 0 16px;
            gap: 8px;
            background: var(--main-color-light);
            border-radius: 6px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .filter-dropdown {
            padding: 0 16px;
            gap: 8px;
            border-radius: 8px;
            background: var(--main-color-light);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 44px;

            svg {
                width: 18px;
            }
        }
    }
}

.business-siso__skeleton,
.store-configuration__skeleton,
.dashboard-skeleton {
    .box-section {
        background: white;
        border: 1px solid var(--color-neutral20);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 24px;
    }

    .row-skeleton {
        th,
        td {
            padding: 0 12px;
        }

        th {
            height: 49px;
        }

        td {
            height: 64px;
        }
    }
}
