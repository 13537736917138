:root {
    //========== BORDER RADIUS =========
    --extra-large-radius: 16px;
    --large-radius: 12px;
    --default-radius: 8px;
    --small-radius: 6px;
    --extra-small-radius: 4px;

    //======== BORDER BOTTOM BOX SHADOW ========

    --shadow-box: 0px 2px 15px 0px #0d0a2c14;

    --shadow-box-small: 0px 2px 15px 0px #0d0a2c14;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: white !important;
}

input,
input::placeholder {
    font-family: Lato;
}

* {
    font-family: Lato;
}

button:hover {
    border-color: var(--main-color-dark) !important;
    color: white !important;

    svg {
        path {
            stroke: white !important;
        }
    }
}

.fade-animation {
    animation-name: fade-animation;
    animation-duration: 0.5s;
}

.fade-animation-top {
    animation-name: fade-animation-top;
    animation-duration: 0.5s;
}

.fade-animation-bottom {
    animation-name: fade-animation-bottom;
    animation-duration: 0.5s;
}

@keyframes fade-animation {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-animation-top {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-animation-bottom {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.cursor-crosshair {
    cursor: crosshair;
}

.no-highlight__text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

p {
    letter-spacing: 0.05px !important;
}
