.export-button {
    width: 136px;
    height: 40px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-border-grey1);
    border-radius: 4px;
    cursor: pointer;
    gap: 12px;
}

.export-button--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
