.error__upload-sellout {
    margin-bottom: 56px;

    .title {
        color: var(--Font, #4b4b4b);
        font-family: 'Lato';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 140% */
    }

    .table-container {
        min-height: unset;
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .button-see-all {
            align-items: center;
            cursor: pointer;

            .text {
                color: var(--main-color-dark);
                font-family: 'Lato';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 142.857% */
                letter-spacing: 0.1px;
            }

            svg {
                path {
                    fill: var(--main-color-dark);
                }
            }
        }
    }

    .column-text {
        -webkit-line-clamp: unset !important;
    }

    .error-rows {
        -webkit-line-clamp: 7 !important;
    }

    .error-column {
        span {
            color: var(--main-color);
            font-weight: 600;
            font-size: var(--small-size);
            cursor: pointer;
            display: flex;
            margin-top: 6px;
        }
    }

    .column-download {
        height: 32px;
        width: max-content;
        padding: 0 12px;
        background: var(--main-color-dark);
        color: white;
        font-size: 11px;
        border-radius: 8px;
    }

    .column-copy {
        height: 32px;
        width: max-content;
        padding: 0 12px;
        border: 1px solid var(--main-color-dark);
        color: var(--main-color-dark);
        font-size: 11px;
        border-radius: 8px;
        display: flex;
        gap: 8px;
    }
}

@media (max-width: 1610px) {
    .error__upload-sellout {
        margin-bottom: 40px;

        .title {
            font-size: 16px;
        }

        .head {
            margin-bottom: 16px;
        }

        .column-download {
            height: 28px;
            padding: 0 10px;
            font-size: 8px;
            border-radius: 6px;
        }
    }
}
