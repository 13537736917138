.chart-box-hover__item {
    height: 100%;
    flex: 1;
    padding: 0;
    position: relative;
}

.chart-box-hover__container {
    height: 100%;
    flex: 1;
    padding: 0;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 55px;
    padding-top: 18px;
    display: flex;
}

.chart-box-hover__info {
    opacity: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(211, 222, 238, 0.2) -17.84%, rgba(211, 222, 238, 0.7) 100%);
    transition: 0.3s;
}

.chart-box-hover__floating-info {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 155px;
    border-radius: 4px;
    top: -10px;
    right: -170px;
    padding: 12px;
    z-index: 2;
    transition: 0.3s;
    display: none;
}

.chart-box-hover__item:nth-child(11) .chart-box-hover__floating-info,
.chart-box-hover__item:nth-child(12) .chart-box-hover__floating-info {
    left: -170px;
    right: unset;
}

.chart-box-hover__item:nth-child(11) .chart-box-hover__floating-info img,
.chart-box-hover__item:nth-child(12) .chart-box-hover__floating-info img {
    left: unset;
    right: -8px;
    transform: rotate(180deg);
}

.chart-box-hover__floating-info .month-year__text {
    color: white;
    margin-bottom: 16px;
}

.chart-box-hover__floating-info .total-sellout__box,
.chart-box-hover__floating-info .total-sellin__box {
    background: var(--color-primary);
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 8px;
}

.chart-box-hover__floating-info .total-sellin__box {
    background: #d1a400;
}

.chart-box-hover__floating-info .total-sellout__text,
.chart-box-hover__floating-info .total-sellin__text {
    font-size: var(--body3);
    color: white;
}

.chart-box-hover__floating-info .total-sellout__number,
.chart-box-hover__floating-info .total-sellin__number {
    color: white;
    margin-bottom: 16px;
    line-height: 20px;
}

.chart-box-hover__floating-info .total-sellin__number {
    margin-bottom: 0;
}

.chart-box-hover__floating-info img {
    position: absolute;
    left: -8px;
    top: calc(50% - 8px);
}

.chart-box-hover__info:hover {
    opacity: 1;
}

.chart-box-hover__info:hover + .chart-box-hover__floating-info {
    display: block;
}
