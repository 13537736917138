.field-input__container {
    width: 100%;
    font-size: 13px !important;
    height: 44px !important;
    border-radius: 8px !important;
    border: 1.5px solid #c4c4c4 !important;
    color: black;
    font-weight: 500;
    margin-right: 16px;
}

.field-input__container::placeholder {
    font-family: Lato;
}

.field-required__button {
    border: 1.5px solid #db3535 !important;
}

.field-required__button::placeholder {
    // color: red;
}

.field-required__text {
    color: red;
    font-size: 11px;
    margin-top: 4px;
}

@media (max-width: 1610px) {
    .field-input__container {
        height: 35px !important;
        font-size: 10px !important;
        border-radius: 6px !important;
        padding-left: 12px;
    }

    .field-required__text {
        font-size: 8px !important;
    }
}
