.button-loading-bar__container{
    width: 100%;
    display: flex;
    justify-content: center;

    .button-loader{
        background: white !important;
        border: 2px solid var(--main-color);

        .progress-button{
            background: var(--main-color);
            top: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            border-radius: 4px;
            animation-name: progress-button-animation;
            animation-duration: 15s;
            animation-fill-mode: forwards;
            animation-timing-function: linear;
        }

        .progress-text{
            justify-content: center;
            align-items: center; 
            display: flex;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            color: black;
            font-weight: 600;
            position: absolute;

        }

        @keyframes progress-button-animation {
               0%{
                width: 0;
               } 
                100%{
                    width: 99%;
                }
        }
    }
}