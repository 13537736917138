.reset-password__container {
    background: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .loading-container {
        width: 160px;
        height: 160px;
        border-radius: 8px;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        left: calc(50vw - 80px);
        top: calc(50vh - 80px);
    }

    .box-container {
        width: 868px;
        background: white;
        height: 530px;
        gap: 50px;
        border-radius: 12px;
        padding: 40px;
        align-items: center;

        .left-section {
            width: 338px;
        }

        .right-section {
            .title {
                font-size: 28px;
                font-weight: 600;
                line-height: 38px;
                letter-spacing: 0em;
                text-align: left;
                color: var(--main-color);
                margin-bottom: 16px;
            }

            .desc {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
                margin-bottom: 32px;
            }

            .input-container {
                margin-bottom: 24px;
                .label {
                    font-family: Lato;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    margin-bottom: 8px;
                }

                .input {
                    height: 44px;
                    border-radius: 8px;
                    border: 1px solid #c4c4c4;

                    font-family: Lato;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                }

                svg {
                    position: absolute;
                    right: 15px;
                    top: 12px;
                    cursor: pointer;
                    z-index: 10;
                }
            }

            .button-submit {
                margin-top: 16px;
                height: 44px;
                border-radius: 8px;
                color: white;
                width: 100%;

                font-family: Lato;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: center;
                background: #c7c7c7;
                cursor: not-allowed;
            }

            .validation-container {
                .validation-item {
                    margin-bottom: 4px;

                    .validation-text {
                        margin-left: 16px;
                        font-family: Lato;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.10000000149011612px;
                        text-align: left;
                        color: #868686;
                    }

                    .validation-text__actived {
                        color: #2ea31f;
                    }
                }
            }

            .valid-button {
                background: var(--main-color);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1610px) {
    .reset-password__container {
        .box-container {
            width: 698px;
            height: 425px;
            border-radius: 10px;
            padding: 32px;
            gap: 42px;

            .left-section {
                width: 270px;

                svg {
                    width: 270px;
                }
            }

            .right-section {
                .title {
                    font-size: 23px;
                    margin-bottom: 10px;
                }

                .desc {
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 24px;
                }

                .input-container {
                    margin-bottom: 8px !important;

                    .label {
                        font-size: 12px;
                        margin-bottom: 4px;
                    }

                    .input {
                        height: 36px;
                        font-size: 12px;
                    }

                    svg {
                        width: 16px;
                        top: 8px;
                        right: 12px;
                    }
                }

                .validation-item {
                    margin-bottom: 0 !important;

                    svg {
                        width: 15px;
                    }
                    .validation-text {
                        font-size: 12px !important;
                        margin-left: 12px !important;
                    }
                }

                .button-submit {
                    margin-top: 8px;
                    height: 36px;
                    border-radius: 6px;
                    font-size: 12px;
                }
            }
        }
    }
}
