.store-config-detail__container {
    display: flex;
    gap: 24px;

    .left-section {
        padding: 24px;
        border-radius: 8px;
        background: white;
        box-shadow: var(--shadow-box);
        flex: 1;

        .head {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 24px;

            .title {
                font-weight: 600;
                font-size: 20px;
                color: var(--main-color-dark);
                display: flex;
                align-items: center;
                gap: 6px;
                margin-left: -8px;
                cursor: pointer;

                svg {
                    path {
                        fill: var(--main-color);
                    }
                }
            }

            .input-container__selectedfilter {
                padding-left: 12px !important;
            }

            .filter-selected__number {
                display: none;
            }
        }

        .body {
            width: 100%;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 12px;

            .section-item {
                padding-right: 24px;
                margin-bottom: 20px;

                .label {
                    font-size: 15px;
                    color: var(--main-color);
                    font-weight: 600;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .right-section {
        width: 440px;
        border-radius: 8px;
        height: max-content;
        background: white;
        box-shadow: var(--shadow-box);
        position: sticky;
        top: 110px;
        padding: 24px;

        .label {
            font-size: 15px;
            color: var(--main-color);
            font-weight: 600;
            margin-bottom: 24px;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            margin-top: 82px;

            button {
                width: 46%;
            }

            .save-button,
            .history-button {
                height: 42px;
                font-size: var(--small-size);
                color: white;
                background: var(--main-color);
            }

            .history-button {
                border: 1px solid var(--main-color);
                background: none;
                color: var(--main-color);
            }
        }
    }

    .checkbox-item {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        margin-right: 12px;

        .checkbox-label {
            font-size: var(--main-size);
        }

        .chakra-checkbox__control {
            border-radius: 4px;
            border: 1px solid var(--Font, #4b4b4b);
        }

        .chakra-checkbox {
            border: #4b4b4b;
        }

        .chakra-checkbox__control[data-checked] {
            background: var(--main-color);
            border: var(--main-color);
        }
    }

    .input-item {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .input-label {
            font-size: var(--main-size);
        }

        input {
            width: 380px;
            font-size: var(--main-size);
            border: none;
            text-align: right;
            padding: 0 12px;
            height: 21px;
            border-radius: 0;
            color: #2f2f2f;
            border-bottom: 1px solid rgb(167, 167, 167);
            outline: none;
        }

        input:focus {
            outline: none;
        }

        input::placeholder {
            font-size: var(--main-size);
            text-align: right;
            color: #939090;
        }
    }

    .text-item {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        margin-right: 12px;

        .text-label {
            font-size: var(--main-size);
        }

        .text-value {
            font-size: var(--main-size);
            color: #2f2f2f;
        }
    }

    .dropdown-item,
    .dropdown-item__head {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        position: relative;

        .dropdown-label {
            font-size: var(--main-size);
        }

        .dropdown-container__store-config-detail {
            .dropdown-value {
                font-size: var(--main-size);
                padding: 0 12px;
                color: #2f2f2f;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 12px;

                svg {
                    margin-bottom: 4px;
                }
            }

            .dropdown-list {
                position: absolute;
                top: 32px;
                display: flex;
                flex-direction: column;
                box-shadow: var(--shadow-box);
                border: 1px solid rgb(242, 242, 242);
                right: 0;
                background: white;
                z-index: 2;

                .dropdown-text {
                    font-size: var(--main-size);
                    padding: 8px 14px;
                    width: 160px;
                    border-bottom: 1px solid rgb(243, 243, 243);
                    cursor: pointer;
                }

                .dropdown-text:hover {
                    background-color: var(--main-color-light);
                }
            }
        }
    }
}

@media (max-width: 1610px) {
    .store-config-detail__container {
        .left-section {
            .head {
                margin-bottom: 16px;

                .title {
                    font-size: var(--large-size);
                }
            }

            .body {
                gap: 10px;

                .section-item {
                    .label {
                        font-size: var(--small-size);
                    }
                }
            }
        }

        .right-section {
            top: 81px;

            .label {
                font-size: var(--small-size);
            }

            .actions {
                margin-top: 68px;

                .save-button,
                .history-button {
                    font-size: 10px;
                    height: 32px;
                }
            }
        }

        .checkbox-item {
            margin-bottom: 8px;

            .checkbox-label {
                font-size: var(--small-size);
            }

            .chakra-checkbox__control {
                height: 14px;
                width: 14px;

                svg {
                    width: 8px;
                }
            }
        }

        .input-item {
            margin-bottom: 8px;

            .input-label {
                font-size: var(--small-size);
            }

            input {
                width: 240px;
                font-size: var(--small-size);
            }

            input::placeholder {
                font-size: var(--small-size);
            }
        }

        .text-item {
            margin-bottom: 8px;

            .text-label,
            .text-value {
                font-size: var(--small-size);
            }
        }

        .dropdown-item,
        .dropdown-item__head {
            margin-bottom: 12px;

            .dropdown-label {
                font-size: var(--small-size);
            }

            .dropdown-container__store-config-detail {
                .dropdown-value {
                    font-size: var(--small-size);
                }

                .dropdown-list {
                    top: 24px;

                    .dropdown-text {
                        font-size: var(--small-size);
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}
