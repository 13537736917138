.table-stock-trail__container{
    @media (max-width: 1610px) {
        .table-column__item{
            .column-text{
                svg{
                    width: 13px;
                }
            }
            .bagde-status-global__container{
                font-size: 10px !important;
            }

            .qty-sellout-column__audit-trail{
                .see-more__button{
                    font-size: 9px !important;
                }

                .qty-item{
                    font-size: 9px !important;
                }
            }
           
        }


        .menu-list__floating-button{
            width: 180px;
            min-width: unset;
        }
    }
}

