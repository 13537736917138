.floating-list__container{
    box-shadow: var(--shadow-box);
    padding: 16px;
    border-radius: 8px;
    position: absolute;
    background: white;
    z-index: 10;
    border: 1px solid rgb(232, 229, 229);
    left: 0;
    width: max-content;
}
