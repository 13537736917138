.table-column__container__achievement {
    min-width: max-content;
    .column__item {
        display: flex;
        border-bottom: 1px solid #cecece;

        justify-content: center;
        align-items: center;
        .column__item-child {
            display: flex;
            width: 100%;
            border-right: 1px solid #cecece;
            padding: 8px 0;

            .column-text__child {
                flex: 1;
            }
        }

        .column-text,
        .column-oddo,
        .column-text__child {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;

            @media (min-width: 1441px) {
                font-size: 13px;
            }
            text-align: center;
            width: 100%;
            color: #2d2d2d;
            padding-right: 16px;
            margin: 0;
        }
    }

    .column__item:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        border-right: 1px solid #cecece;
        background: white;
    }
}

.table-column__semester1,
.table-column__semester2 {
    background: #fbf5f3;

    .column__item:first-child {
        background: #fbf5f3;
    }
}

.table-column__total {
    background: #ffe7df;
    .column__item:first-child {
        background: #ffe7df;
    }
}

.empty-state__table {
    width: 100%;
    height: 100%;
    background: rgb(210, 188, 188);
    height: 35px;
    margin-top: -8px;
    margin-bottom: -8px;
}

.table-column__all {
    width: 2064px;
}
