.filter-year__container {
    align-items: center;
    gap: 12px;
}

.filter-year__switch {
    border: 1px solid var(--skeleton-color);
    border-radius: 4px;
    align-items: center;
    width: 104px;
    height: 36px;
    padding: 10px 8px;
    justify-content: center;
}

.filter-year__text {
    margin: 0 12px;
}
