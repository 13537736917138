.dashboard-container {
    .left-section__dashboard {
        height: 100vh;
        background: var(--default-white-color);
        position: sticky;
        top: 0;
        left: 0;
        box-shadow: 0px 2px 15px 0px #0d0a2c08;
        z-index: 30;
        border: 1px solid var(--skeleton-color);
    }

    .right-section__dashboard {
        flex: 1;
        background: var(--semantic-heron);

        .content-box__container {
            padding: 36px 41px 60px;
        }
    }
}

@media (max-width: 1610px) {
    .dashboard-container {
        .content-box__container {
            padding: 26px 34px 48px !important;
        }
    }
}
