.button-add-flow__container{
    position: fixed;
    bottom: 64px;
    right: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .add-type__container{
      .add-type__item{
        background: white;
        box-shadow: var(--shadow-box);
        margin-bottom: 12px;
        padding: 8px 16px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        min-width: 160px;
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .add-type__item:hover{
        background: var(--main-color-light-active);
        color: var(--main-color);
        font-weight: 600;

        svg{
            path, rect{
                stroke: var(--main-color);
            }
        }
      }



    }

    .button-add__element{
        font-size: var(--main-size);
        color: var(--main-color);
        background: var(--main-color-light-active);
        border: 2px solid var(--main-color);
        border-radius: 24px;
        display: flex;
        gap: 16px;
     
    }

}