.installation__container{
    padding: 24px;

    .title{
        font-size: 20px;
        font-weight: 600;
    }

    p{
        font-size: var(--main-size);
    }

    blockquote{
        font-size: var(--main-size);
        background: black;
        color: white;
        padding: 8px 12px;
        border-radius: 4px;
    }

    .loading-container{
        width: 100%;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}