.button-outline__container {
    padding: 0px 12px 0px 12px !important;

    border: 1px solid !important;
    align-items: center !important;
    font-size: var(--body2) !important;
    background: white !important;
    display: flex;
}

.button-regular__container {
    padding: 0px 12px 0px 12px !important;

    display: flex;
    align-items: center !important;
    font-size: var(--body2) !important;
    color: white !important;
}

.button-outline__container:hover,
.button-regular__container:hover {
    /* background: var(--color-primary50) !important; */
}

.button-export__container {
    gap: 12px;
    padding: 0 12px;
    height: 40px;
    min-width: 132px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--color-neutral20);
    cursor: pointer;
}

.button-regular__container--loading,
.button-regular__container--loading:hover {
    background: var(--color-primary20) !important;
    cursor: not-allowed;
}
