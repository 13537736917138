.navbar-container,
.navbar-container__small {
    width: 280px;
    padding: 20px 0;
    animation-name: navbar-animate;
    animation-duration: 0.5s;

    .navbar-header {
        padding: 0 24px;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 50px;
        z-index: 21;

        .arrow-left {
            position: absolute;
            right: 12px;
            bottom: 2px;
            cursor: pointer;
            z-index: 22;
        }
    }

    .active-chevron {
        transform: rotate(180deg);
    }

    .navbar-list {
        .main-menu__text {
            padding: 0 24px;
            font-size: var(--main-size);
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: left;
            color: var(--color-primary);
            margin-bottom: 16px;
        }

        .navbar-item {
            overflow: hidden;
            height: 100%;
            margin-bottom: 8px;
            transition: 0.4s;

            .selected-indicator {
                background: #ffd700;
                position: absolute;
                left: 0;
                width: 4px;
                bottom: 0;
                border-top-right-radius: 3px;
                top: 0;
                border-bottom-right-radius: 3px;
            }

            .navbar-item__parent {
                margin-bottom: 8px;
                padding: 0 24px 0 40px;
                align-items: center;
                gap: 12px;
                height: 44px;
                position: relative;
                cursor: pointer;

                .navbar-icon__container {
                    path {
                        stroke: var(--color-blue1) !important;
                    }
                }

                .navbar-text {
                    font-size: var(--main-size);
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: var(--color-blue1);
                }

                .chevron-down__icon {
                    position: absolute;
                    right: 24px;
                    transition: 0.5s;
                }
            }

            .navbar-item__parent:hover {
                background: var(--color-primary10);
                transition: 0.6s;
            }

            .navbar-item__parent:has(> .selected-indicator) {
                background: var(--semantic-heron);
                transition: 0.6s;

                .navbar-text {
                    font-weight: 700;
                    color: var(--color-primary);
                }

                .navbar-icon__container {
                    path {
                        stroke: var(--color-primary) !important;
                    }
                }
            }

            .navbar-item__child {
                margin: 0 24px;
                height: 36px;
                margin-bottom: 8px;
                padding: 0 46px;
                align-items: center;
                cursor: pointer;
                transition: 0.6s;

                .navbar-text {
                    font-size: var(--main-size);
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    margin-left: 16px;
                    color: var(--color-blue1);
                }
            }

            .navbar-item__child-actived {
                .navbar-text {
                    font-weight: 700;
                    color: var(--color-primary);
                }
            }
        }
    }
}

.navbar-container__small {
    width: 84px;
    animation-name: navbar-animate;
    animation-duration: 0.5s;
    padding-top: 32px;

    .navbar-header {
        justify-content: center;
        padding: 0 12px !important  ;
        position: relative;
        z-index: 21;

        svg:nth-child(2) {
            position: absolute;
            bottom: 0;
            right: 6px;
            cursor: pointer;
        }
    }

    .navbar-item__parent {
        justify-content: center;
        padding: 0 12px !important;
    }

    .navbar-item__parent:has(> .selected-indicator) {
        background: var(--semantic-heron);
        transition: 0.6s;
    }
}

@keyframes navbar-animate {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 1610px) {
    .navbar-container,
    .navbar-container__small {
        width: 240px;
        padding: 12px 0 !important;

        .main-menu__text {
            font-size: 12px !important;
            padding: 0 18px !important;
            margin-bottom: 8px !important;
        }

        .navbar-header {
            padding: 0 18px;
            margin-bottom: 32px !important;

            svg {
                width: 16px;
                right: 10px !important;
            }
            .sagitarius-logo {
                width: 110px;
            }
        }

        .navbar-item {
            .navbar-item__parent {
                height: 38px !important;
                padding: 0 18px !important;
                margin-bottom: 12px !important;

                .chevron-down__icon {
                    right: 16px !important;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .navbar-item__child {
                margin: 0 18px 12px !important;
                height: 32px !important;
                padding: 0 32px !important;
            }

            .navbar-text {
                font-size: 12px !important;
            }
        }
    }

    .navbar-container__small {
        width: 70px !important;
        padding: 24px 0 !important;

        .navbar-header {
            margin-bottom: 40px !important;
        }

        svg:first-child {
            width: 13px;
            height: 13px;
        }

        svg:nth-child(2) {
            width: 14px;
            height: 14px;
            bottom: 2px !important;
            right: 4px !important;
        }

        .navbar-item {
            svg {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }
}

// @media (max-width: 1380px ) {
//     .navbar-container,
//     .navbar-container__small{
//         width: 200px;
//         padding: 10px 0 !important;

//         .main-menu__text{
//             font-size: 9px !important;
//             padding: 0 16px !important;
//             margin-bottom: 4px !important;
//         }

//         .navbar-header{
//             padding: 0 18px;
//             margin-bottom: 24px !important;

//             svg{
//                 width: 12px;
//                 right: 8px !important;
//             }
//             .sagitarius-logo{
//                 width: 90px;
//             }
//         }

//         .navbar-item{

//             .navbar-item__parent{
//                 height: 34px !important;
//                 padding: 0 16px !important;
//                 margin-bottom: 8px !important;
//                 gap: 8px !important;

//                 .chevron-down__icon{
//                     right: 16px !important;
//                 }
//             }

//             .navbar-item__child{
//                 margin: 0 16px 12px !important;
//                 height: 28px !important;
//                 padding: 0 20px !important;
//             }

//             .navbar-text{
//                 font-size: 9px !important;
//             }

//             svg{
//                 width: 14px;
//                 height: 14px;
//             }
//         }
//     }

//     .navbar-container__small{
//         width: 58px !important;
//         padding: 20px 0 !important;

//         .navbar-header{
//             margin-bottom: 32px !important;
//         }

//         svg:first-child{
//             width: 11px;
//             height: 11px;
//         }

//         svg:nth-child(2){
//             width: 11px;
//             height: 11px;
//             bottom: 0 !important;
//             right: 6px !important;
//         }

//         .navbar-item{
//             svg{
//                 width: 13px !important;
//                 height: 13px !important;
//             }
//         }
//     }
// }
