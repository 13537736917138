.sync-component__container {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-neutral10);
    height: 65px;
}

.label__text {
    line-height: 19.6px;
    margin-bottom: 4px;
}

.description__text {
    color: var(--color-neutral50);
    line-height: 16.8px;
}

.sync__button {
    height: 36px !important;
    width: 82px;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background: white;
}

.sync__text {
    line-height: 19.6px;
}
