.filter-date-container{
  .box-filter{
    border-radius: 8px;
    border: 1.5px solid var(--main-color);
    overflow: hidden;
    position: relative;
    margin-right: 10px;
    cursor: pointer;

    .search-input{
        padding: 12px 44px 12px 16px;
        border: none;
        background: white;
        height: 44px;
        width: 200px;
        color: var(--main-color);
        font-size: var(--main-size);
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        cursor: pointer;


    }

    .search-input::placeholder{
        color: var(--main-color);
    }

    .search-icon{
        position: absolute;
        width: 44px;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--main-color-light);
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1.5px solid #C4C4C4;
        z-index: 10;
    }
  }
}

@media (max-width: 1610px) {
  .filter-date-container{

    .box-filter{
        margin-right: 6px;
        border-radius: 6px;

          .search-input{
            font-size: 12px;
            line-height: 14px;
            height: 35px;
            width: 155px;
            padding: 11px 36px 9px 14px;
          }
    }

      .search-icon{
          width: 35px !important;

          svg{
              width: 18px;
          }
      }
  }
}