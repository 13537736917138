.history__upload-sellout {
    margin-bottom: 56px;

    .title {
        color: var(--Font, #4b4b4b);
        font-family: 'Lato';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .button-see-all {
            align-items: center;
            cursor: pointer;

            .text {
                color: var(--main-color-dark);
                font-family: 'Lato';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.1px;
            }

            svg {
                path {
                    fill: var(--main-color-dark);
                }
            }
        }
    }

    .column-text {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
}

@media (max-width: 1610px) {
    .history__upload-sellout {
        margin-bottom: 40px;

        .title {
            font-size: 16px;
        }

        .head {
            margin-bottom: 16px;

            .button-see-all {
                .text {
                    font-size: 12px;
                }

                svg {
                    width: 18px;
                }
            }
        }

        .table-template__container {
            .table-column__item {
                .bagde-status-global__container {
                    font-size: 9px !important;
                }
            }
        }
    }
}
