.sync-modal__container {
    width: 967px;
    height: max-content;
    border-radius: 8px;
    padding: 24px 40px;
}

.title {
    font-size: var(--heading6);
}

.progress-list__container {
    gap: 24px;
    justify-content: space-between;
}
.progress-item__container {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
.progress-bar__container {
    background: var(--color-neutral10);
    height: 6px;
    flex: 1;
    border-radius: 88px;
}

.progress-bar {
    height: 6px;
    border-radius: 88px;
    animation-name: progressBarAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.circle__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

@keyframes progressBarAnimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.process-container {
    padding: 8px;
    width: 32px;
    height: 32px;
    background: #dadada;
    border-radius: 50%;
    margin: 0;
    background: #dee7ed;
    position: relative;
}

.blue-progress--circle1,
.blue-progress--circle2,
.blue-progress--circle3 {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    animation: growAndFade 1s infinite ease-out;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.blue-progress--circle1 {
    animation-delay: 0.5s;
}

.blue-progress--circle2 {
    animation-delay: 0.75s;
}

.blue-progress--circle3 {
    animation-delay: 1s;
}

@keyframes growAndFade {
    0% {
        opacity: 0.5;
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.box-info__container {
    background: var(--semantic-heron);
    border-radius: 8px;
    width: 100%;
    margin-top: 40px;
    padding: 16px;
    margin-bottom: 40px;
}

.toggle__button {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 16px;
}

.info__container {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-danger10);
    padding: 12px;
    border-radius: 8px;
    gap: 8px;
}
