.right-side__floating-container{
    position: fixed;
    right: 0;
    top: 75px;
    bottom: 0;
    background: white;
    width: 0;
    opacity: 0;
    box-shadow: var(--shadow-box);
    border-left: 1px solid rgb(224, 222, 222);
    z-index: 2;
    padding: 24px;
    display: flex;
    transition: 0.5s;
    flex-direction: column;

    .hide-menu{
        font-size: var(--small-size);
        color: var(--main-color);
    }

    .head{
        width: 300px;
        justify-content: space-between;
        margin-bottom: 24px;

        .title{
            font-size: var(--main-size);
            font-weight: 500;
        }

        svg{
            width: 22px;
        }

        input{
            font-size: var(--small-size);
            height: 32px;
        }
    }

    .body{
        width: 300px;
        flex-direction: column;
        gap: 18px;
        .item{
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;

            .sketch-picker{
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }

            
            .label{
                font-size: var(--small-size);
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 6px;

                svg{
                    width: 20px;
                }
            }

            .name{
                font-size: var(--small-size);
                font-weight: 400;
             
                height: 28px;
                padding: 0 12px;
                display: flex;
                gap: 2px;
                align-items: center;
            }


            .id-column{
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
            }

            .select-parent__container{
                position: absolute;
                box-shadow: var(--shadow-box);
                width: 160px;
                background: white;
                right: 0;
                top: 48px;
                min-height: 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 4px;
                border: 1px solid rgb(220, 220, 220);

                .not-found{
                    font-size: var(--small-size);
                    text-align: center;
                    height: 100%;
                }

                .opiton-item{
                    font-size: var(--small-size);
                    padding: 8px 12px;
                    border-bottom: 1px solid rgb(224, 223, 223);
                    cursor: pointer;
                }

                .opiton-item:hover{
                    background: var(--main-color-light);
                }

            }
          

            .edited:hover{
                border: 1px solid rgb(210, 210, 210);
                transition: 0.5s;
                border-radius: 4px;
            }

            .color{
                border-radius: 50%;
                width: 16px;
                height: 16px;
                margin: 0 12px;
                border-color: black;
                border: 1px solid black;
                cursor: pointer;
                position: relative;
            }
        }
    }

    .footer{
        width: 300px;
        display: flex;
        margin: auto 0 0;
        justify-content: center;
        gap: 20px;
        align-items: center;
        font-weight: 500;
        flex-direction: column;
        cursor: pointer;

        svg{
            width: 20px;
        }

        .delete-item{
            color: rgb(242, 115, 115);
            font-size: var(--small-size) !important; 
           
        }

        .save-item{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--main-color);
            color: var(--main-color);
            border-radius: 8px;
            font-size: var(--small-size) !important; 
            height: 48px;
            width: 100%;
        }

    }
}

.right-side__floating-container__show{
    width: 350px;
    opacity: 1;
}