.table-component__container {
}

.table-head {
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: var(--body2) !important;
    color: var(--semantic-text) !important;
    height: 49px !important;
    padding: 16px 12px !important;
    font-family: 'Lato' !important;
}

.body-row {
    border-bottom: 1px solid var(--color-neutral10);
}

.table-body {
    height: 64px;
    padding: 12px !important;
}

.title-text {
    line-height: 16.8px;
    font-size: var(--body2);
}

.description-text {
    font-size: var(--body2);
    line-height: 16.8px;
}
