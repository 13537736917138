.global-loader__container{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.3);

    .spinner-box{
        width: 220px;
        height: 220px;
        border-radius: 8px;
        background: rgba(0,0,0,0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}