.popup-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 25;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation-name: overlay-animation;
        background: rgba(0,0,0,0.3);
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        z-index: 25;
    }

    .box-container{
        background: white;
        z-index: 25;
        position: relative;
        border-radius: 12px;
        width: 400px;
        animation-name: popup-animation;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        box-shadow: var(--shadow-box);
        padding: 12px 12px 24px;

        .head{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-top: 64px;

            .close-icon{
                background: #ed9494;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 12px;
                top: 12px;
                cursor: pointer;
            }

            .icon{
                background: var(--main-color-light-hover);
                border-radius: 50%;
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .body{
            display: flex;
            flex-direction: column;

            justify-content: center;
            padding: 24px;

            .title{
                font-size: var(--large-size);
                font-weight: 600;
                text-align: center;
            }

            .desc{
                margin-top: 8px;
                font-size: var(--main-size);
                text-align: center;

            }
        }

        .footer{
            display: flex;
            justify-content: center;
            gap: 16px;
            padding: 0 32px;
            margin-top: 24px;

            .cancel{
                flex: 1;
                border: 1px solid var(--main-color) ;
                background: none;
                font-size: var(--main-size);
                color:var(--main-color) ;
            }

            .submit{
                flex: 1;
                background: var(--main-color);
                font-size: var(--main-size);
                color: white;
                width: max-content;
            }
        }
    }
}

@keyframes popup-animation {
    0%{
       opacity: 0;
       transform: translateY(20px);
      
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes overlay-animation {
    0%{
      opacity: 0;
    }
    100%{
       opacity: 1;
    }
}

@media (max-width: 1610px) {
    .popup-container{
        .box-container{
            width: 326px;

            .head{
                margin-top: 48px;

                .close-icon{
                    width: 24px;
                    height: 24px;

                    svg{
                        width: 8px;
                        height: 8px;
                    }
                }

                .icon{
                    width: 160px;
                    height: 160px;

                    svg{
                        width: 160px;
                        height: 160px;
                    }

                    .warning-icon{
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            .body{
                padding: 20px;

                .title{
                    font-size: 13px;
                }

                .desc{
                    font-size: 12px;
                }
            }

            .footer{
                margin-top: 18px;
                gap: 12px;
                padding: 0 20px;

                .cancel,
                .submit{
                    font-size: 12px;
                    height: 34px;
                }

            }
        }
    }
}