.error-page-content__container {
    background: white;
    border: 1px solid var(--color-neutral10);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 154px);
}

.error-page__title {
    font-size: var(--heading6);
    margin-bottom: 4px;
    text-align: center;
}

.error-page__description {
    margin-bottom: 32px;
    font-size: var(--body1);
    max-width: 700px;
    text-align: center;
}

.go-to-home__button {
    background: var(--color-primary) !important;
    color: white;
    width: 175px;
    height: 42px;
    font-size: var(--body1);
    font-weight: 700;
    margin: 0 auto;
}
