.table-template__container {
    width: 100%;

    .table-container {
        background: white;
        height: 100%;
        box-shadow: 0px 2px 15px 0px #0d0a2c14;

        border-radius: 12px;
        min-height: 300px;

        .table-head__item::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 8px;
            top: 8px;
            // border-right: 1px solid #dfdfdf;
        }

        .divider::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            border-right: 1px solid #dfdfdf;
        }

        // .table-head__item:last-child:before,
        .table-column__item:last-child:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            // border-left: 1px solid #dfdfdf;
        }

        table {
            .table-head__item {
                padding: 14px;
            }

            .row-head__container {
                overflow: hidden;
                // ----------------------------- TABLE HEAD ----------------------------

                .table-head__item {
                    font-family: 'Lato';
                    background: var(--main-color-light);
                    position: sticky;
                    z-index: 2;
                    text-transform: unset;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #4b4b4b;
                    vertical-align: baseline;

                    .icon-container {
                        position: absolute;
                        top: 12px;
                        right: 16px;
                        cursor: pointer;
                    }

                    @media (max-width: 1640px) {
                        font-size: 12px;
                    }
                }

                .table-head__item:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .table-head__item:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
    // ----------------------------- TABLE COLUMN ----------------------------

    .row-column__container {
        background: white;

        .table-column__item {
            padding: 14px;
            position: relative;
            border-bottom: 1px solid #cecece;
            vertical-align: top;
            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
            }
        }
    }

    .row-column__container:last-child {
        .table-column__item {
            border-bottom: unset;
        }
    }

    //------------------------------- PAGINATION ----------------------------------------

    .section-pagination__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        .showing-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: #5c6c8b;

            span {
                font-weight: 600;
                margin-left: 4px;
            }
        }

        .pagination-container {
            margin-top: 12px;

            ul {
                display: flex;
                list-style-type: none;

                .selected {
                    a {
                        color: white !important;
                        background: var(--main-color-hover);
                    }
                }

                li {
                    height: 36px;
                    text-decoration: none;
                    margin: 0 2px;
                    padding: 0 2px;
                    box-shadow: var(--shadow-box-small);
                    border-radius: 50%;

                    a {
                        display: flex;
                        justify-content: center;
                        box-shadow: 0px 2px 15px 0px #0d0a2c14;
                        align-items: center;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 1px;
                        background: white;
                        color: black;

                        path {
                            fill: black;
                        }
                    }
                }
            }
        }
    }
}

.table-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 1610px) {
    .table-template__container {
        .table-container {
            min-height: 280px;
        }
        .table-head__item {
            padding: 13px !important;
            font-size: 12px !important;
            line-height: 16px !important;
        }
        .table-column__item {
            padding: 9px 11px !important;

            p {
                font-size: 12px !important;
                line-height: 16px !important;
            }
        }

        .section-pagination__container {
            margin-top: 10px !important;

            .showing-text {
                font-size: 12px;
            }

            li {
                height: 28px !important;

                a {
                    height: 28px !important;
                    width: 28px !important;
                    font-size: 12px !important;
                }

                svg {
                    width: 20px !important;
                }
            }
        }
    }
}
