.tooltip__container {
    position: relative;
}

.tooltip__content {
    position: absolute;
    background: black;
    border-radius: 4px;
    padding: 16px;
    width: max-content;
    height: max-content;
    z-index: 4;
    display: none;
}

.tooltip-trigger__button:hover + .tooltip__content {
    transition: 0.2s;
    display: inline;
}

.icon__container {
    position: absolute;
    top: -12px;
    transform: rotate(90deg);
}
