.master-data__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 12px 24px 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    flex: 1;
}

.title {
    font-size: var(--heading7);
    line-height: 30px;
    display: flex;
    gap: 16px;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-neutral10);
}
