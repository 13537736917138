.file-selected-info__container {
    height: 68px;
    width: 100%;
    background-color: var(--semantic-heron);
    margin-bottom: 32px;
    border-radius: 8px;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;

    .file-info__container {
        margin-left: 16px;
    }

    .file-name__text {
        font-size: var(--body2);
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: left;
        margin-bottom: 4px;
        max-width: 260px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .file-size__text {
        font-size: var(--body2);
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #7a7a7a;
    }

    .change-file__container {
        align-items: center;
        gap: 12px;
        width: 114px;
        justify-content: center;
        cursor: pointer;

        .change-file__text {
            font-size: var(--body2);
            line-height: 19.6px;
            letter-spacing: 0.005em;
            text-align: left;
            color: var(--color-primary);
        }
    }
}

@media (max-width: 1610px) {
    .file-selected-info__container {
        height: 60px;

        .file-info__container {
            margin-left: 12px;
        }

        img:first {
            width: 20px;
            height: 20px;
        }

        .file-name__text {
            font-size: var(--body3);
            margin-bottom: 2px;
            max-width: 240px;
        }

        .file-size__text {
            font-size: var(--body3);
        }

        .change-file__container {
            width: 75px;

            img {
                width: 10px;
                height: 10px;
            }

            .change-file__text {
                font-size: var(--body3);
            }
        }
    }
}
