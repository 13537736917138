.reminder-container__upload-sellout {
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 4px 16px #0000001a;
    padding: 0 24px 0 0;
    height: 164px;
    margin-bottom: 56px;
    overflow: hidden;

    .reminder-information__container {
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: -webkit-fill-available;
        padding: 16px 0;

        .title {
            color: var(--Font, #00497a);
            font-family: 'Lato';
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
            letter-spacing: 0.1px;
            margin-bottom: 16px;
        }

        .description {
            color: var(--Font, #4b4b4b);
            font-family: 'Lato';
            margin-bottom: 16px;
            margin-top: 0;
            font-size: var(--main-size);
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: left;
            padding-right: 20%;

            span {
                color: var(--Font, #00497a);
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .week-reminder__box {
            width: 100%;
            border-radius: 12px;
            background: #fbf4f2;
            padding: 13px 64px;
            height: -webkit-fill-available;
            position: relative;

            .date-text {
                color: var(--Font, #4b4b4b);
                font-family: 'Lato';
                font-size: var(--main-size);
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.1px;
                margin-bottom: 13px;
            }

            .weeks {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .week-item {
                    margin-top: 2px;
                    .week {
                        color: var(--Font, #4b4b4b);
                        font-family: Lato;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; /* 133.333% */
                        letter-spacing: 0.06px;
                    }
                }
            }

            .checkpoint-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                margin: 0 10px;

                .line {
                    height: 1px;
                    background: #4b4b4b;
                    position: absolute;
                    left: 0;
                    right: 0;
                }

                .circle-checkpoint {
                    position: relative;
                    background: #fbf4f2;
                    padding: 0 6px;
                }
            }

            .arrow {
                .arrow-left {
                    position: absolute;
                    left: 16px;
                    top: 70px;
                }

                .arrow-right {
                    position: absolute;
                    right: 16px;
                    top: 70px;
                }
            }
        }
    }
}

@media (max-width: 1610px) {
    .reminder-container__upload-sellout {
        height: 136px;
        margin-bottom: 42px;

        svg {
            width: 280px;
            height: 138px;
        }

        .reminder-information__container {
            margin-left: 30px;
            padding: 14px 0;

            .title {
                font-size: 14px;
                margin-bottom: 12px;
                line-height: 17px;
            }

            .description {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
