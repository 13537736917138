.alert__container {
    align-items: center;
    justify-content: space-between;
    background: var(--color-info10);
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 14px 18px;
}

.alert__text span {
    font-weight: 700;
}
