.het-modal__container {
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
}

.het-modal__container img {
    margin-bottom: 32px;
}

.title {
    font-size: var(--heading6);
    line-height: 36px;
    text-align: center;
    margin-bottom: 8px;
}

.description {
    margin-bottom: 32px;
    line-height: 19.6px;
    text-align: center;
}

.description span {
    font-weight: 700;
}
