.filter-month__container {
    align-items: center;
    gap: 12px;
}

.filter-month__switch {
    border: 1px solid var(--skeleton-color);
    border-radius: 4px;
    align-items: center;
    height: 36px;
    padding: 10px 8px;
    justify-content: center;
    gap: 12px;
}

.filter-month__text {
}
