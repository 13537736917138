.bagde-status-global__container{
   padding: 2px 8px;
   text-align: center;
   border-radius: 12px;
   width: max-content;
   font-size: var(--small-size) !important;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: 0.10000000149011612px;

}