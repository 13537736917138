.upload-zone__container{
    position: fixed;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay{
        background: black;
        opacity: 0.2;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 20;
    }
    .box-content{
        background: white;
        width: 80vw;
        position: relative;
        z-index: 20;
        height: 80vh;
        border-radius: 12px;
        box-shadow: var(--shadow-box-small);
        flex-direction: column;
        height: calc(100vh - 180px);
        display: flex;
        flex-direction: column;

        .head{
            align-items: center;
            justify-content: space-between;
            height: max-content;
            border-bottom: 1px solid #e4e4e4;
            width: 100%;
            padding: 24px;


            .icon-back{
                width: 32px;
                height: 32px;
                background: var(--main-color-light-active);
                display: flex;
                align-items : center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
            }

            .title{
                font-size: 18px;
                font-weight: 600;
                line-height: 38px;
                cursor: pointer;
            }

            .download-button{
                border-radius: 16px;
                border:1px solid var(--main-color-dark);
                width: 32px;
                height: 32px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.5s;


                .download-text{
                    width: 0;
                    overflow: hidden;
                    font-size: 12px;
                    font-weight: 600;
                    white-space: nowrap;
                    opacity: 0;
                    color: var(--main-color-dark);
                }
            }

            .download-button:hover{

                width: 200px;
                padding: 0 16px;
                border-radius: 8px;

                .download-text{
                    width: max-content;
                    opacity: 1;
                    margin-right: 8px;
                }
            }
        }

        .body{
            justify-content: center;
            align-items: center;
            flex: 1;
            padding: 24px;
            flex-direction: column;

            .upload-zone__container{
                position: relative;
                width: 100%;
                flex: 1;
                border-radius: 12px;
                border: 1.5px dashed var(--Grey-Darkest, #868686);
                background: var(--White, #FFF);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .button-text{
                    font-size: 18px;
                    font-weight: 500;
                    color: #54616c;
                }

                .upload-zone{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    max-width: unset;
                    max-height: unset;
                    height: unset;
                }
            }

            .selected-file__container{
                background: #e2ffef;

                .button-text{
                    color: #588669;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .submit{
                margin-top: 24px;
                align-self: end;
                width: 120px;
                background: var(--main-color);
                color: white;
                border-radius: 8px;
                font-size: 14px;
            
            }

        
        }
    }
}