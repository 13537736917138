.search-bar__container {
    position: relative;
}

.search-bar__container img {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 16px;
}

.search-bar__container input {
    width: 300px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--color-neutral20);
    padding-left: 40px;
    font-size: var(--body2);
}

.search-bar__container input::placeholder {
    color: var(--color-neutral40) !important;
    font-size: var(--body2);
    margin-bottom: 4px;
}
