.section-table__container__achievement {
    overflow-x: scroll;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 36px;
}

.section-table__container__achievement::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.section-table__container__achievement::-webkit-scrollbar {
    height: 3px;
    width: 0;
}

.section-table__container__achievement::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--main-color);
}
