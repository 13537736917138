.table-siso__container {
    background: white;
    border: 1px solid var(--color-neutral20);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.empty-state-button-regular__content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.empty-state-button-regular__content img {
    margin-bottom: 0 !important;
}

.empty-state-upload__text {
    color: white;
}

.empty-state-help__text {
    color: var(--semantic-text);
}

.table-siso__container .price-number {
    text-align: right;
    line-height: 40px;
    font-size: var(--body3);
}

.table-siso__container .brand-text {
    text-align: left;
    line-height: 40px;
    font-size: var(--body3);
    text-transform: uppercase;
}

.table-siso__container .text-bold {
    font-weight: 700 !important;
    text-transform: none;
    font-size: var(--body1) !important ;
}

.table-siso__container .badge-component {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 25px;
    width: max-content;
    padding: 0 14px;
}

.table-siso__container .badge-value__text {
    line-height: 17px;
    font-size: var(--body3);
}

.table-siso__container .devider-component {
    position: relative;
    width: 32px;
    height: 64px;
}

.devider-component::after {
    border-right: 1px solid var(--color-border-grey1);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
}

.table-siso__container td:has(.devider-component) {
    padding: 0 !important;
}

.table-siso__container .row-skeleton .table-column__item {
    height: 64px;
}

.table-siso__container .tooltip__content {
    top: 28px;
    left: -265px;
}

@media (max-width: 1441px) {
    .table-siso__container .brand-text,
    .table-siso__container .price-number,
    .table-siso__container .price-number,
    .table-siso__container .badge-value__text {
        font-size: var(--body3) !important;
    }
}
