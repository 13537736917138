.tabs-container__popup {
    gap: 16px;
    margin-bottom: 16px;

    .tab-item {
        height: 36px;
        width: 100%;
        padding: 10px 0;
        border-radius: 4px;
        border: 1px solid var(--color-primary);
        min-width: 73px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        .tab-name {
            color: var(--color-primary);
            text-align: center;
            font-size: var(--body2);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-family: 'Lato', sans-serif;
        }

        svg {
            margin-right: 12px;
            width: 16px;
            height: 16px;
        }
    }

    .tab-active {
        background: var(--main-color);
        .tab-name {
            color: white;
            font-weight: 600;
        }
    }

    .tab-inactive {
        background: #d9d9d9;
        color: white;
        border: 1px solid #bfbfbf;
        cursor: not-allowed;

        .tab-name {
            color: grey;
        }
    }
}

@media (max-width: 1610px) {
    .tabs-container__popup {
        gap: 12px;
        margin-bottom: 12px;

        .tab-item {
            padding: 6px !important;
            min-width: 62px !important;

            svg {
                width: 18px;
                height: 18px;
                margin-right: 8px !important;
            }

            .tab-name {
                font-size: 10px !important;
                line-height: 14px !important;
            }
        }
    }
}
