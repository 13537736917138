.richtext-editor__container{
    position: relative;

    .button-container{
        position: absolute;
        top: 21px;
        right: 7px;
        height: 0;
        background: white;
        color: var(--main-color);
        font-size: var(--main-size);
    }
}