.documentation-header__container{
    height: 72px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    padding: 0 24px;
    display: flex;
    align-items: center;
    box-shadow: var(--shadow-box);
    background: white;
    z-index: 2;

    .documentation-header__wrapper{
        margin: 0 auto;
        position: relative;
        justify-content: space-between;
        width: 100%;
        position: relative;

        .doc-text{
            font-size: 10px;
            border-radius: 4px;
            background: var(--main-color-light);
            padding: 4px 8px;
            left: 143px;
            top: -1px;
            height: max-content;
            font-weight: 500;
            position: absolute;
        }
    }
}