.upload-full-zone__container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 26;
    background-color: rgba(0, 91, 153, 0.6);
    padding: 64px;
    animation: upload-zone-animation;
    animation-duration: 0.5s;

    .text {
        text-align: center;
        width: 100%;
        font-size: 48px;
        color: white;
        font-weight: 700;
    }

    .close-button {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

@keyframes upload-zone-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1610px) {
    .upload-full-zone__container {
        .text {
            font-size: 36px;
        }

        .boundary-container {
            svg {
                width: 48px;
                height: 48px;
            }
        }

        .close-button {
            width: 28px;
            height: 28px;
            top: 8px;
            right: 8px;

            svg {
                width: 10px;
            }
        }
    }
}
