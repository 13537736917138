.forbidden-page__container {
    width: 100%;
    height: calc(100vh - 180px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon{
        position: relative;

        .search{
            position: absolute;

            animation-name: animation-search__forbidden-page;
            animation-iteration-count: infinite;
            animation-duration: 15s;
            animation-timing-function: ease-out;
        }
    }

    .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: var(--main-color);
        margin: 32px;
    }
}


@media (max-width: 1610px) {
    .forbidden-page__container {
        .desc {
            font-size: 12px;
        }

        .empty-image{
            width: 260px;
        }

        .search{
            width: 140px;
        }
    }
}


@keyframes animation-search__forbidden-page {
    0%{
        top: 32px;
        left: 32px;
    }
    10%{
        top: 38px;
        left: 64px;
    }
    20%{
        top: 32px;
        left: 100px;
    }
    30%{
        top: 64px;
        left: 100px;
    }
    40%{
        top: 32px;
        left: 100px;
    }
    50%{
        top: 24px;
        left: 24px;
    }
    60%{
        top: 32px;
        left: 32px;
    }
    70%{
        top: 64px;
        left: 32px;
    }
    80%{
        top: 64px;
        left: 100px;
    }
    90%{
        top: 64px;
        left: 64px;
    }
    100%{
        top: 32px;
        left: 32px;
    }
}