.empty-state-global__container {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50vh;

    img {
        margin-bottom: 24px;
    }

    .empty-state__text {
        font-size: var(--heading7);
        line-height: 30px;
        margin-bottom: 8px;
    }

    .empty-state__description {
        line-height: 20px;
    }
}
