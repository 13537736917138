.folder-structure__container {
    padding: 24px;

    .title {
        font-size: 20px;
        font-weight: 600;
    }

    .content {
        height: calc(100vh - 185px);
    }

    .spinner {
        width: 60vw;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }

    .default-custom__node,
    .default-group__node {
        font-size: var(--main-size);
        font-weight: 600;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        border-radius: 6px;
        align-items: center;
        // background: var(--main-color-light);
        // border: 1px solid var(--main-color-dark);
        // color: var(--main-color-dark);
        position: relative;
        height: 100%;
        transition: 0.5s;
        width: 100%;
        text-align: center;
        box-shadow: var(--shadow-box);

        .menu-container {
            position: absolute;
            right: -132px;
            bottom: -39px;
            background: white;
            box-shadow: var(--shadow-box);
            border-radius: 8px;

            .menu-item {
                padding: 8px 20px 8px 16px;
                border-bottom: 1px solid rgb(228, 226, 226);
                align-items: center;
                gap: 12px;
                cursor: pointer;
            }

            .menu-item:hover {
                background: var(--main-color-light);
            }
        }

        .group-title {
            position: absolute;
            left: 13px;
            top: -41px;
            font-size: var(--large-size);
            background: #005c78;
            color: white;
            min-width: 120px;
            padding: 0 16px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            border: none;
            padding-left: 0;
            background: none;
            outline: none;
            width: 100%;
            text-align: center;
            transition: 0.5s;
        }

        .list-parent {
            position: absolute;
            padding: 8px;
            right: -180px;
            top: 40px;
            background: white;
            box-shadow: var(--shadow-box);

            .filter-button {
                min-width: 140px;
            }
        }
    }

    .default-group__node {
        background: #a4cffb24;
        border: 2px dashed #094060;
        z-index: 0;
    }

    .react-flow__node-defaultGroup {
        background: none !important;
        border: none !important ;
    }
    .selected-node {
        border: none;
        border-radius: 12px;
    }

    .edge-label {
        position: absolute;
        z-index: 99;
        font-size: var(--small-size);
        font-family: Lato;
        color: var(--main-color-dark);
        background: white;
        font-weight: 600;
    }

    .flow-notes__container {
        max-width: 120px;
        min-height: 80px;
        max-height: 170px;
        background: #ffd7f0;
        border: 2px solid #dc89bc;
        padding: 12px 12px 24px;
        position: relative;
        overflow: hidden;

        p {
            font-size: 10px;
            line-height: 12px;
        }
    }

    .button-save {
        box-shadow: var(--shadow-box);
        font-size: var(--main-size);

        background: #fec4ff;
        color: #69087f;
        border: 2px solid #931291;
    }

    .button-save:hover {
        background: #903a92;
        color: white;
    }
}
