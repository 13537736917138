.add-button__container{
    background: var(--main-color);
    border-radius: 8px;
    padding: 12px 20px;
    display: flex;
    margin-right: 3px;
    cursor: pointer;

    .button-text{
        font-size: var(--main-size);
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: white;
        margin-left: 8px;
    }
}

@media (max-width: 1610px)  {
    .add-button__container{
        padding: 8px 14px;
        border-radius: 7px;

        .button-text{
            font-size: var(--small-size);
        }

        svg{
            width: 16px;
        }
    }
}