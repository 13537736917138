.download-notification__container {
    position: fixed;
    left: 80px;
    bottom: 80px;
    z-index: 999999999;
    animation-name: snackbar-animation;
    animation-duration: 0.5s;

    .box {
        min-width: 600px;
        border-radius: 4px;
        padding: 12px 16px;
        align-items: flex-start;

        img {
            width: 20px;
            margin-top: 4px;
        }

        .info-container {
            margin-left: 12px;
            width: 100%;

            .title {
                font-size: var(--body1);
            }

            .description {
                font-family: 'Lato';
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
                margin: 0;
            }
        }
    }
}

@keyframes snackbar-animation {
    0% {
        opacity: 0;
        transform: translateX(-120px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 1610px) {
    .download-notification__container {
        .box {
            min-width: 460px;
            padding: 12px;
            border-radius: 6px;

            svg {
                width: 19px;
                height: 19px;
            }

            .info-container {
                margin-left: 10px;

                .title {
                    font-size: var(--small-size);
                    line-height: 16px;
                    margin-bottom: 4px;
                }

                .description {
                    font-size: var(--small-size);
                    line-height: 16px;
                }
            }

            .detail-message__container {
                margin-top: 12px !important;
                padding-top: 12px !important;

                .detail-message {
                    margin-bottom: 6px !important;
                    font-size: 10px !important;
                }
            }
        }
    }
}
