.list-item__text {
    padding-left: 6px;
}

.list-item__text span {
    margin-right: 6px;
}

.statistic-content__text--1,
.statistic-content__text--2 {
    text-align: center;
}

.statistic-content__text--2 {
    margin-top: 8px;
}

.list-table-item__text {
    display: flex;
    gap: 6px;
    padding-left: 6px;
    margin-top: 10px;
}
