.modal-content__popup-success {
    width: 100%;
    min-width: 520px;
    padding: 40px;

    .modal-stack {
        justify-content: center;
        gap: 10px;
    }

    .modal-text {
        font-weight: 500;
        font-size: 14px;
        color: #4b4b4b;
        font-family: 'Lato';
    }
}

.modal-content__poup-failed {
    width: 100%;
    min-width: 520px;
    padding: 40px;

    .modal-stack {
        justify-content: center;
        gap: 10px;
    }

    .modal-text {
        font-weight: 500;
        font-size: 14px;
        color: #4b4b4b;
        font-family: 'Lato';
        text-align: center;
    }

    .error-message {
        font-weight: 500;
        background-color: #f2dbd7;
        color: #4b4b4b;
        padding: 15px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .error-text {
            text-overflow: ellipsis;
            max-height: 45px;
            white-space: nowrap;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: var(--small-size);
        }

        .read-more {
            color: #e03138;
            text-decoration: underline;
            cursor: pointer;
            font-size: var(--small-size);
        }
    }
}

.modal-content__poup-failed-detail {
    width: 620px !important;
    padding: 40px;
    background: white;
    border-radius: 12px;

    .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #4b4b4b;
        margin-bottom: 24px;

        span {
            color: red;
        }
    }

    .error-message__container {
        background-color: #fceaeb;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 12px;

        .error-message {
            font-size: var(--main-size);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 !important;
            margin-bottom: 0 !important;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .error-message,
    .error-message__multiple {
        font-weight: 500;
        background-color: #fceaeb;
        color: #4b4b4b;
        padding: 15px;
        border-radius: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .error-text {
            text-overflow: ellipsis;
            white-space: pre;
            overflow: hidden;
            font-size: var(--main-size);
            margin-bottom: 4px;
        }

        .read-more {
            color: #e03138;
            text-decoration: underline;
            font-size: var(--main-size);
            font-weight: 500;
            cursor: pointer;
        }
    }

    .error-desc {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: #4b4b4b;
        max-width: 500px;
        margin-bottom: 108px;
    }

    .action-info__container {
        width: 100%;
        border-top: 1px solid #c4c4c4;
        padding: 12px 0;

        .information-text {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.005em;
            color: #868686;
            margin-bottom: 8px;
        }

        .information-label {
            width: 82px;
            margin-right: 8px;
        }
        .information-label,
        .information-value {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.005em;
            text-align: left;
            color: #4b4b4b;
        }
        .information-value {
            font-weight: 600;
            margin: 0;
        }
    }

    button {
        border: 1px solid #005b99;
        border-radius: 8px;
        height: 44px;
        background: white;
        color: #005b99;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: center;

        svg {
            margin-right: 10px;
        }
    }

    .icon-copy {
        margin-right: 12px;
    }

    .path-warning {
        animation-name: path-warning-animation;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes path-warning-animation {
        0% {
            transform: translateY(-10px);
        }
        25% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(-5px);
        }
        75% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-10px);
        }
    }
}
