.filters-parahub__container .title {
    font-size: 24px;
    margin-bottom: 8px;
}

.filters-parahub__container .description-container {
    align-items: center;
}

.filters-parahub__container .description {
    display: flex;
    font-size: 12px;
    margin-left: 6px;
}

.filters-parahub__container .description span {
    font-weight: 700;
    margin: 0 4px;
}

.filters-parahub__container .box-filters {
    background: var(--semantic-heron);
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    animation-duration: 0.2s;
    margin-top: 24px;
    gap: 24px;
}

.filters-parahub__container .filter-button {
    min-width: 90px;
    width: max-content;
    height: 40px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-border-grey1);
    border-radius: 4px;
    cursor: pointer;
    gap: 12px;
}

.filter-button .filter-indicator {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background: var(--color-primary);
    margin-top: 2px;
}

.filters-parahub__container .filter-buttons__container {
    gap: 12px;
    justify-content: flex-end;
    align-items: flex-end;
    width: max-content;
}

.filters-parahub__container .tooltip__content {
    top: 30px;
    left: -10px;
}

.filters-parahub__container .filter-selected__container {
    align-items: center;
    gap: 16px;
    border-top: 1px solid var(--color-neutral10);
    width: 100%;
    margin-top: 24px;
    padding-top: 24px;
}

.filters-parahub__container .filter-selected__item {
    background: var(--semantic-heron);
    border-radius: 2px;
    align-items: center;
    height: 33px;
    padding: 0 8px;
}

.filters-parahub__container .filter-selected__item p {
    font-size: var(--body3);
}

.filters-parahub__container .selected-text {
    margin: 0 8px 0 4px;
}
.filters-parahub__container .filter-selected__item img {
    cursor: pointer;
}
