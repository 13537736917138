.spotlight-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 22;
}

.spotlight-element-selected__container {
    background: white;
    position: relative;
    z-index: 23;
    transition: 0.5s;
    padding: 16px;
}
