.upload-container__upload-sellout {
    margin-bottom: 56px;
    .title {
        color: var(--Font, #4b4b4b);
        font-family: 'Lato';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
        margin-bottom: 16px;
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        div:has(> .menu-list__floating-button) {
            left: 0 !important;
        }

        .download-menu__container {
            position: relative;
        }

        .download-menu__button {
            border-radius: 8px;
            border: 1px solid var(--Foundation-Red-red-500, var(--main-color));
            background: var(--White, #fff);
            padding: 12px;
            height: 50px;

            color: var(--main-color);
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.1px;

            svg {
                margin-left: 8px;
            }
        }

        .menu-list__container {
            right: unset;
            left: 0;
            min-width: 240px;
        }

        .download-menu__item {
            padding: 16px;
            font-size: var(--main-size);
            width: 100%;
            font-family: Lato;
        }

        .download-menu__item:hover {
            color: var(--main-color-dark);
        }

        .tutorial-button__container {
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .text {
                color: var(--main-color-dark);
                font-family: 'Lato';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.1px;
            }

            svg {
                path {
                    fill: var(--main-color-dark);
                }
            }
        }
    }

    .body {
        .upload-zone__container {
            position: relative;
            width: 100%;
            height: 175px;
            border-radius: 12px;
            border: 1.5px dashed var(--Grey-Darkest, #868686);
            background: var(--White, #fff);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 10;

            @media (min-width: 1600px) {
                height: 225px;
            }

            .upload-text {
                color: var(--Grey-Darkest, #868686);
                font-family: 'Lato';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
                letter-spacing: 0.1px;
            }
        }
    }

    .zone-actived {
        background: var(--main-color-light) !important;

        .upload-text {
            color: var(--main-color) !important;
            font-weight: 500 !important;
        }

        svg {
            path {
                stroke: var(--main-color) !important;
            }
        }
    }
}

@media (max-width: 1610px) {
    .upload-container__upload-sellout {
        margin-bottom: 48px;

        .title {
            font-size: 16px;
            margin-bottom: 12px;
        }

        .head {
            margin-bottom: 20px;

            .download-menu__button {
                height: 38px;
                font-size: 12px;
                border-radius: 6px;

                svg {
                    width: 16px;
                }
            }

            .menu-list__container {
                width: 200px;
            }

            .download-menu__item {
                padding: 12px;
                font-size: 12px;
            }

            .tutorial-button__container {
                .text {
                    font-size: 12px;
                }

                svg {
                    width: 20px;
                }
            }
        }

        .body {
            .upload-zone__container {
                svg {
                    width: 42px;
                    height: 42px;
                }
                .upload-text {
                    font-size: 13px;
                }
            }
        }
    }
}
